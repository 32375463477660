import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'store/actions/index';

import Auth from 'containers/Auth/Auth';
import AppRoute from 'hoc/AppRoute/AppRoute';
import MainLayout from 'hoc/Layout/DesignLayout/MainLayout/MainLayout';
import SupportSettings from 'containers/SupportSettings/SupportSettings';
import Customers from 'containers/Customers/Customers';
import Logout from 'containers/Auth/Logout/Logout';

class SupportApp extends Component {

    constructor(props) {
        super(props);
        props.onSetAdmin(false, "support");
        props.onSetAuthRedirectPath('/support');
    }

    componentDidMount() {
        if(!this.props.isAuthenticated) {
            this.props.onTryAutoSignup(false); 
        }           
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/support/auth" component={Auth} />
                <Redirect to="/support/auth" />
            </Switch>
        );
        

        if ( this.props.isAuthenticated ) {
            routes = (
                <Switch>
                    <AppRoute path="/support/customers"  component={Customers} layout={MainLayout} />
                    <AppRoute path="/support/settings" component={SupportSettings} layout={MainLayout}  />
                    <Route path="/support/logout" component={Logout} />
                    <Route path="/support/auth" component={Auth} />
                    <Redirect to="/support/customers" />
                </Switch>
            );
        }
        
        
        return (
            <React.Fragment>
                {routes}
            </React.Fragment>
            
        );
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAdmin: (isAdmin, appType) => dispatch( actions.setAdmin(isAdmin, appType) ),
        onSetAuthRedirectPath: (path) => dispatch( actions.setAuthRedirectPath(path) ),
        onTryAutoSignup: (isAdmin) => dispatch( actions.authCheckState(isAdmin) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(SupportApp);