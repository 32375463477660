import React, { Component } from 'react';
import { connect } from 'react-redux';

import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CancelIcon from '@material-ui/icons/Cancel';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';

import DataTable from 'components/DataTable/DataTable';
import styles from 'assets/jss/containers/ordersStyle';
import { infoColor, successColor, warningColor, dangerColor } from "assets/jss/appStyle";
import * as actions from 'store/actions/index';
import MapWrapped from 'components/MapWrapped/MapWrapped';
import { updateObject } from 'shared/utility';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 8,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

class ArrivalsList extends Component {

    state = {
        columnConfig: [
            {
                field: 'order_status',
                title: '',
                formatHandler:  order => this.statusIconHandler(order)
            },
            {
                field: 'client',
                title: 'Kunde'
            },
            {
                field: 'vehicle_id',
                title: 'Fahrzeug',
                adminOnly: true
            },
            {
                field: 'order_id',
                title: 'Auftrag'
            },
            {
                field: 'planned_arrival',
                title: 'geplante Ankunft'
            },
            {
                field: 'estimated_arrival',
                title: 'Ankunftszeit ca.',
                formatHandler: order => this.estimatedArrivalFormatHandler(order)
            },
            {
                field: 'estimated_distance',
                title: 'KM',
                formatHandler: order => this.estimatedDistanceFormatHandler(order)
            }
        ],
        actionFields: [
            {
                tooltip: 'show on map',
                icon: RoomRoundedIcon,
                clickedHandler: order => this.orderShowMapHandler(order)
            }
        ],
        orderStatusConfig: {
            31: {
                icon: <WatchLaterIcon htmlColor={warningColor[0]} />,
                label: "unterbrochen",
                color: warningColor[0],
                checkboxClass: "badgeWarn"
            },
            32: {
                icon: <CancelIcon htmlColor={dangerColor[0]} />,
                label: "abgebrochen",
                color: dangerColor[0],
                checkboxClass: "badgeDanger"
            },
            50: {
                icon: <LocalShippingIcon htmlColor={infoColor[0]} />,
                label: "Anfahrt Lieferadresse",
                color: infoColor[0],
                checkboxClass: "badgeInfo"
            },
            51: {
                icon: <DoneAllIcon htmlColor={successColor[0]} />,
                label: "angekommen",
                color: successColor[0],
                checkboxClass: "badgeSuccess"
            },
        },
        tableSortBy: {
            field: "order_id", 
            ascOrder: true
        },

        // columns : [
        //   { field: 'order_status', headerName: 'ID', width: 70 },
        //   { field: 'client', headerName: 'Kunde', width: 130 },
        //   { field: 'order_id', headerName: 'Auftrag', width: 130 },
        //   {
        //     field: 'planned_arrival',
        //     headerName: 'geplante Ankunft',
        //     width: 90,
        //   },
        //   {
        //     field: 'estimated_arrival',
        //     headerName: 'Ankunftszeit ca.',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: params => {
                
        //         const { classes } = this.props;
        //         const orderStatus = params.getValue('order_status');

        //         if(orderStatus === "50") {
        //             return params.hasOwnProperty("estimated_arrival")?params.getValue('estimated_arrival'): <Skeleton />;
        //         } 
        //         else {
        //             const statusCaptions = {
        //                 31: {title:"unterbrochen", colorClass:classes.textWarning}, 
        //                 32: {title:"abgebrochen", colorClass:classes.textError}, 
        //                 51: {title:"angekommen", colorClass:classes.textSuccess}
        //             };
        //             return (
        //                 <Typography variant="subtitle1" className={statusCaptions[orderStatus].colorClass} gutterBottom>
        //                     {statusCaptions[orderStatus].title}
        //                 </Typography>
        //             );
        //         }
        //     }
        //     //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
        //   },
        // ],

        showMapDialog: false,
        selectOrder: null,
    };



    shouldComponentUpdate(nextProps, nextState) { 
        if(this.props.orders !== nextProps.orders || 
            this.props.filters !== nextProps.filters || 
            this.state.showMapDialog !== nextState.showMapDialog ||
            this.state.tableSortBy !== nextState.tableSortBy) {
            return true;
            }
        else
            return false;
    }

    statusIconHandler = order => {
        return this.state.orderStatusConfig[order.order_status].icon;
    }
    
    estimatedArrivalFormatHandler = order => {
        const { classes } = this.props;

        if(order.order_status === "50") {
            return order.hasOwnProperty("estimated_arrival")?order.estimated_arrival: <Skeleton />;
        } 
        else {
            const statusCaptions = {
                31: {title:"unterbrochen", colorClass:classes.textWarning}, 
                32: {title:"abgebrochen", colorClass:classes.textError}, 
                51: {title:"angekommen", colorClass:classes.textSuccess}
            };
            return (
                <Typography variant="subtitle1" className={statusCaptions[order.order_status].colorClass} gutterBottom>
                    {statusCaptions[order.order_status].title}
                </Typography>
            );
        }
    }

    estimatedDistanceFormatHandler = order => {
        if(order.order_status === "50") {
            return order.hasOwnProperty("estimated_distance")? order.estimated_distance + " KM": <Skeleton />
        } else {
            return "---";
        }
    }

    orderShowMapHandler = order => {
        this.setState({
            showMapDialog: true,
            selectOrder: order,
        });
    }

    dialogClosedHandler = (updateList = false) => {
        this.setState({
            showMapDialog: false,
            selectOrder: null
        });

    }

    tableSortHandler = (sortField, sortDir = false) => {

        let updatedState = {...this.state};
        
        const updatedSortBy = updateObject( updatedState.tableSortBy, {field: sortField, ascOrder: !sortDir});

        updatedState = updateObject(updatedState, {tableSortBy: updatedSortBy});
        this.setState(updatedState);
    } 

    render() {
        const { classes } = this.props;

        const filteredOrdersList = this.props.orders.filter(order => {
            let shouldReturn = this.props.filters.statusList.includes(+order.order_status);

            const searchString = this.props.filters.searchString.trim();
            if(shouldReturn && searchString !== '') {
                
                shouldReturn = shouldReturn && this.props.columnFilters.some(key => {
                    try {
                        return order[key].toString().toLowerCase().includes(searchString.toLowerCase());
                    } catch (error) {
                        return false;
                    }
                });
            }

            return shouldReturn;
        });

        const dialogContent = this.state.showMapDialog? (
            <Dialog fullScreen open={this.state.showMapDialog} onClose={this.dialogClosedHandler} TransitionComponent={Transition}>
                <AppBar color="transparent">
                    <Toolbar>
                        <Typography variant="h6" className={classes.grow}>
                            {`Auftrag: ${this.state.selectOrder.order_id}`}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={this.dialogClosedHandler} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <MapWrapped
                    dialogOrder={true}
                    selectedOrder={this.state.selectOrder}  />
            </Dialog>
        ) : null;

        const checkBoxFilters = Object.keys(this.state.orderStatusConfig).map(statusKey => {
            const orderStatus = this.state.orderStatusConfig[statusKey];
            return (
                <FormControlLabel
                    key={statusKey}
                    control={
                        <StyledBadge
                            classes={{ badge: classes[orderStatus.checkboxClass] }}
                            // className={classes.badgeMargin}
                            badgeContent={this.props.statusCount[statusKey]}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}>
                            <Checkbox 
                                name={`status$statusKey`}
                                value={statusKey} 
                                checked={this.props.filters.statusList.includes(+statusKey)}
                                onChange={this.props.onStatusFilterChanged}
                                style={{ color: orderStatus.color }} />
                        </StyledBadge>
                    }
                    label={orderStatus.label}
                />
            );
        });

        return (    
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth={false}>
                    <TableContainer component={Paper}>
                        <Toolbar>
                            <strong style={{marginRight:"10px"}}>Auftragsstatus:  </strong>

                            <FormGroup row edge="start">
                                {checkBoxFilters}
                            </FormGroup>

                            <div className={classes.grow} />
                            <Box edge="end">

                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                    <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search…"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        value={this.props.filters.searchString}
                                        onChange= {this.props.onSearchStringChanged}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                            </Box>
                            
                        </Toolbar>
                        <DataTable 
                            sortHandle = {this.tableSortHandler}
                            sortBy = {this.state.tableSortBy}
                            tableData = {filteredOrdersList} 
                            columnConfig = {this.state.columnConfig}
                            isAdmin = {this.props.isAdmin}
                            columnFilters = {this.props.columnFilters}
                            actions = {this.props.isAdmin? this.state.actionFields: null} />
                    </TableContainer>
                    {dialogContent}
                </Container>

            </React.Fragment>
            
        );
    }
}



const mapStateToProps = state => {
    return {
        orders: state.order.orders,
        statusCount: state.order.statusCount,
        columnFilters: state.order.columnFilters,
        filters: state.order.filters,
        loading: state.order.loading,
        isAdmin: state.auth.isAdmin,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSearchStringChanged: (event) => dispatch( actions.searchStringFilterUpdate(event.target.value) ),
        onStatusFilterChanged: (event) => dispatch( actions.statusFiltersUpdate(Number.parseInt(event.target.value)) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(ArrivalsList) );
