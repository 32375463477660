import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions/index';
import { Route, Switch, withRouter } from 'react-router-dom';

import AdminApp from 'hoc/Layout/AppLayout/AdminApp/AdminApp';
import TrackingApp from 'hoc/Layout/AppLayout/TrackingApp/TrackingApp';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SupportApp from 'hoc/Layout/AppLayout/SupportApp/SupportApp';
import Spinner from 'components/UI/Spinner/Spinner';



class App extends Component {

    componentDidMount() {
        if(!this.props.themeColorsLoaded) {
            let host = window.location.host;
            let parts = host.split(".");
            let subdomain = null;
            if (parts.length >= 3) {
                subdomain = parts[0];
                this.props.onGetThemeColors(subdomain);
            } else {
                this.props.onSetDefaultThemeColors(this.props.themeColors, true);
            }
        }
    }

    render() {

        if(this.props.themeColorsLoaded) {
            const theme = createMuiTheme({
                palette: {
                    primary: {
                        main: this.props.themeColors.primary
                    },
                    secondary: {
                        main: this.props.themeColors.secondary
                    }
                }
            });
    
            return (
                <MuiThemeProvider theme={theme}>
                    <Switch>
                        <Route path="/admin" component={AdminApp} />
                        <Route path="/support" component={SupportApp} />
                        <Route path="/"  component={TrackingApp} />
                    </Switch>
                </MuiThemeProvider>
            );
        } else {
            return <Spinner />;
        }
    }
}

const mapStateToProps = state => {
    return {
        themeColorsLoaded: state.auth.themeColorsLoaded,
        themeColors: state.auth.themeColors,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetThemeColors: (subdomain) => dispatch( actions.getThemeColors(subdomain) ),
        onSetDefaultThemeColors: (themeColors, isDefault) => dispatch( actions.setThemeColors(themeColors, isDefault) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(withRouter( App ));;