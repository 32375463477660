import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from 'store/actions/index';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from 'components/Navigation/AppBar/AppBar';
import Drawer from 'components/Navigation/Drawer/Drawer';
import styles from 'assets/jss/layouts/layoutStyle';


class MainLayout extends Component {

    state = {
        drawerOpen: false,
        activeNav: null,
    }
    
    navClickedHandler = (event, path, samePath) => {
        if(samePath)
            event.preventDefault();
        else
            this.props.history.push(path);
    }

    drawerClosedHandler = () => this.setState({drawerOpen:false});
    drawerOpenedHandler = () => this.setState({drawerOpen:true});

    refreshOrdersHandler = () => {
        if(this.props.isAdmin) {
            this.props.onFetchAdminOrders(this.props.token, this.props.compId);
        } else {
            if(this.props.trackingInfo.trackingId && !this.props.trackingInfo.error) {
                this.props.onFetchOrders(this.props.trackingInfo.trackingId); 
            } else {
                this.props.history.push('/tracking');
            }
        }
    }

    render() {

        const { classes } = this.props;
        let appbarTitle = "Arrival Monitor";
        switch(this.props.appType) {
            case "tracking":
                appbarTitle = this.props.trackingInfo.compName + " Sendungsverfolgung";
                break;
            case "admin":
                appbarTitle = this.props.compName + " Arrival Monitor Administration";
                break;
            case "support":
                appbarTitle = "Arrival Monitor Support Administration";
                break;
            default:
                appbarTitle = "Arrival Monitor";
                break;

        }

        return (
            <div className={classes.root}>
            <CssBaseline />

            <AppBar
                isDataLoading = {this.props.isDataLoading}
                drawerOpen = {this.state.drawerOpen}
                isAdmin = {this.props.isAdmin}
                lastUpdate = {this.props.lastUpdate}
                isAuthenticated = {this.props.isAuthenticated}
                drawerOpenClicked = {this.drawerOpenedHandler}
                refreshButtonClicked = {this.refreshOrdersHandler}
                title={appbarTitle} /> 

            <Drawer 
                open = {this.state.drawerOpen}
                isAdmin = {this.props.isAdmin}
                appType = {this.props.appType}
                linkClicked = {this.navClickedHandler}
                activePath = {this.props.history.location.pathname}
                isAuthenticated = {this.props.isAuthenticated}
                closeClicked = {this.drawerClosedHandler} />

            
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {this.props.children}
            </main>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        isAdmin: state.auth.isAdmin,
        appType: state.auth.appType,
        lastUpdate: state.order.lastUpdate,
        readyToLoad: state.order.readyToLoad,
        token: state.auth.token,
        trackingInfo: state.auth.trackingInfo,
        compName: state.auth.compName,
        compId: state.auth.compId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchOrders: (trackid) => dispatch( actions.fetchOrders(trackid) ),
        onFetchAdminOrders: (authToken, compId) => dispatch( actions.fetchAdminOrders(authToken, compId) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(withStyles(styles, {withTheme: true})(MainLayout)));