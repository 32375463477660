import * as actionTypes from './actionTypes';
import {serverApi} from '../../axiosHelper';

export const searchStringFilterUpdate = ( searchString ) => {
    return {
        type: actionTypes.FILTERS_UPDATE_SEARCH_STRING,
        searchString: searchString
    };
};

export const statusFiltersUpdate = ( filterStatus ) => {
    return {
        type: actionTypes.FILTERS_UPDATE_STATUS_LIST,
        filterStatus: filterStatus
    };
};

export const fetchOrdersSuccess = ( orders, columnFilters ) => {
    const orderStatusCount = {
        31: 0, 32: 0, 50: 0, 51: 0 
    }
    orders.forEach(function (order) {
        orderStatusCount[order.order_status] = orderStatusCount[order.order_status] + 1
      });
    return {
        type: actionTypes.FETCH_ORDERS_SUCCESS,
        orders: orders,
        orderStatusCount: orderStatusCount,
        columnFilters: columnFilters
    };
};

export const fetchOrdersFail = ( error, readyToLoad ) => {
    return {
        type: actionTypes.FETCH_ORDERS_FAIL,
        error: error,
        readyToLoad: readyToLoad
    };
};

export const fetchOrdersStart = () => {
    return {
        type: actionTypes.FETCH_ORDERS_START
    };
};

export const fetchOrders = (trackid) => {
    return (dispatch, getState) => {
        dispatch(fetchOrdersStart());
        const queryParams = '?trackid=' + trackid;
        serverApi.get( '/order' + queryParams)
            .then( res => {
                if(res.data.success === true) {
                    dispatch(fetchOrdersSuccess(res.data.rows, res.data.columnFilters));
                    if(getState().order.loading && getState().order.ordersLoaded) {
                        dispatch(fetchDistances())
                    }
                } else {
                    dispatch(fetchOrdersFail(res.data.error, false));
                }
            } )
            .catch( error => {
                if (error.response) {
                    dispatch(fetchOrdersFail(error.response.data, false));
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    dispatch(fetchOrdersFail(error.message, true));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    dispatch(fetchOrdersFail(error.message, false));
                }
            } );
    };
};

export const fetchAdminOrders = (token, compId) => {
    return (dispatch, getState) => {
        dispatch(fetchOrdersStart());
        const queryParams = '?authToken=' + token + '&compid=' + compId;
        serverApi.get( '/order/admin' + queryParams)
            .then( res => {
                if(res.data.success === true) {
                    dispatch(fetchOrdersSuccess(res.data.rows, null));
                    if(getState().order.loading && getState().order.ordersLoaded) {
                        dispatch(fetchDistances())
                    }
                } else {
                    dispatch(fetchOrdersFail(res.data.error));
                }
            } )
            .catch( err => {
                dispatch(fetchOrdersFail(err));
            } );
    };
};

export const fetchOrderDistancesSuccess = () => {
    return {
        type: actionTypes.FETCH_ORDERS_DISTANCES_SUCCESS
    };
};

export const fetchOrderDistancesFail = ( error ) => {
    return {
        type: actionTypes.FETCH_ORDERS_DISTANCES_FAIL,
        error: error
    };
};

export const fetchOrderDistancesUpdate = (offset, distances) => {
    return {
        type: actionTypes.FETCH_ORDERS_DISTANCES_UPDATE,
        offset: offset,
        distances: distances
    };
};

export const fetchDistances = () => {
    return dispatch => {
        dispatch(isNextDistances());
    };
};

const isNextDistances = () => {
    return (dispatch, getState) => {
        // if offset greater than or equals to orders length, than all distances are been fetched
        if(getState().order.distanceOffset <  getState().order.orders.length) {
            dispatch(fetchNextDistances());
        } else {
            dispatch(fetchOrderDistancesSuccess());
        }
        
    };
};


const fetchNextDistances = () => {
    return (dispatch, getState) => {

        let order = null;

        let offset = getState().order.distanceOffset;
        let tempLimit = getState().order.distanceLimit;

        let originsAll = "";
        let destAll = "";
        let orderIndexAll = "";
        while( tempLimit > 0 && offset < getState().order.orders.length) {
            order = getState().order.orders[offset];
            if(order.order_status === "50") {
                orderIndexAll += "index_" + offset + "|";
                originsAll += order.vehicle_lat + "," + order.vehicle_lon + "|";
                destAll += order.order_lat + "," + order.order_lon + "|";
                tempLimit--;
            }
            offset++;
        }

        if(orderIndexAll !== "") {
            serverApi.get( '/google/distancematrix', {
                params: {
                    orderIndexAll: orderIndexAll.slice(0, -1),
                    originsAll: originsAll.slice(0, -1),
                    destAll: destAll.slice(0, -1)
                }
              })
                .then( res => {
                    if(res.data.success === true) {
                        dispatch(fetchOrderDistancesUpdate(offset, res.data.distances));
                    } else {
                        dispatch(fetchOrderDistancesFail(res.data.error));
                    }
                } )
                .then(() => {
                    setTimeout(() => {
                        dispatch(isNextDistances())
                    }, 1000);
                })
                .catch( err => {
                    dispatch(fetchOrderDistancesFail(err));
                } );
        } else {
            dispatch(fetchOrderDistancesSuccess(offset));
        }
    };
};