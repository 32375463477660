import React from 'react';
import Link from '@material-ui/core/Link';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../UI/Title/Title';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { infoColor, grayColor } from "assets/jss/appStyle";


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#555',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
root: {
    '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    },
},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 700,
    },
    tableActionButton: {
      width: "27px",
      height: "27px",
      padding: "0"
    },
    tableActionButtonIcon: {
      width: "17px",
      height: "17px"
    },
    tableCellHeader:{
        background: "#ffffff",
        cursor: "pointer", 
        "&:hover": {
            background: "#efefef"
        }
    },
    tableCellHeaderSorter:{

        display: "flex", 
        flexFlow: "row", 
        justifyContent: "flex-start",
    }
}));

const DataTable = (props) => {
    const classes = useStyles();

    let rowContent = null;
    rowContent = props.columnConfig.filter(column => {
                    return (props.columnFilters.includes(column.field)) && 
                        (!('adminOnly' in column) || (('adminOnly' in column) && props.isAdmin));
                })
                .map( column => {
                    let cellContent = column.title;
                    if(column.title.length > 1) {
                        if(column.field === props.sortBy.field) {
                            cellContent = (
                                <TableCell 
                                    className={`${classes.tableCellHeader} ${classes.tableCellHeaderSorter}`} 
                                    key = {column.field}
                                    onClick={() => props.sortHandle(column.field, props.sortBy.ascOrder)}>
                                    {props.sortBy.ascOrder?(<ArrowUpwardIcon />): (<ArrowDownwardIcon />)}
                                    <Typography>
                                        {column.title}
                                    </Typography>
                                </TableCell>
                            );
                        } else {
                            cellContent = (
                                <TableCell
                                    className={`${classes.tableCellHeader}`} 
                                    key = {column.field}
                                    onClick={() => props.sortHandle(column.field)}>
                                    <Typography >
                                        {column.title}
                                    </Typography>
                                </TableCell>
                            );
                        }
    
                        return cellContent;
                    } else {
                        return (<TableCell key = {column.field}>
                            {cellContent}                          
                        </TableCell>);
                    }
                });

    const tableHeadersContent = (
        <TableRow>
            {rowContent}
        </TableRow>
    );

    const sortedData = props.tableData.sort((a, b) => {
        if(props.sortBy.field === "estimated_arrival" || props.sortBy.field === "estimated_distance") {
            const field = props.sortBy.field + "_orig";
            const aVal = a.hasOwnProperty(field)?a[field]:1000000;
            const bVal = b.hasOwnProperty(field)?b[field]:1000000;

            if(props.sortBy.ascOrder) {
                return (aVal > bVal) ? 1 : -1;
            } else {
                return (aVal < bVal) ? 1 : -1;
            }
        } else {
            if(props.sortBy.ascOrder) {
                return (a[props.sortBy.field] > b[props.sortBy.field]) ? 1 : -1;
            } else {
                return (a[props.sortBy.field] < b[props.sortBy.field]) ? 1 : -1;
            }
        }   
    });
    
    const tableBodyContent = sortedData.map((dataRow,rowIndex) => {
        rowContent = props.columnConfig.filter(column => {
                        return (props.columnFilters.includes(column.field)) && 
                            (!('adminOnly' in column) || (('adminOnly' in column) && props.isAdmin));
                    })
                    .map((column, colIndex) => (   
                        <StyledTableCell key={column.field}>
                            {
                                (column.formatHandler)? column.formatHandler(dataRow): dataRow[column.field]
                            }
                        </StyledTableCell>
                    ));

        let rowActions = null;
        if(props.actions) {
            rowActions = (<StyledTableCell key={"actions_cell_" + rowIndex}>
                            {props.actions.map((action, actionIndex) => {
                                if(dataRow.order_status === "50" || dataRow.order_status === "31") {
                                    return (
                                        <Tooltip
                                            key={"action_" + actionIndex}
                                            id="tooltip-top"
                                            title={action.tooltip}
                                            placement="top">
                                            <IconButton
                                                aria-label="Copy link"
                                                onClick={event => action.clickedHandler(dataRow)}
                                                className={classes.tableActionButton}>
                                                <action.icon
                                                    htmlColor={dataRow.order_status === "50" ? infoColor[0]:grayColor[0]}
                                                    className={
                                                    classes.tableActionButtonIcon
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    );
                                } else {
                                    return null;
                                }
                                
                            })}
                        </StyledTableCell>);
        }

        rowContent.push(rowActions);

        return (
            <StyledTableRow  key={"row_" + rowIndex}>
                    {rowContent} 
            </StyledTableRow>
        );
    });

    const sectionTitle = props.sectionTitle ? <Title>{props.sectionTitle}</Title> : null;

    const seeMoreLink = props.seeMoreLink ? (
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={props.seeMoreClicked}>
                    See more {props.seeMoreTitle}
                </Link>
            </div>
        ) : null;

    


    return (
        <React.Fragment>
            
            {sectionTitle}

            <Table className={classes.table}>
                
                <TableHead>
                    {tableHeadersContent}
                </TableHead>
                
                <TableBody>
                    {tableBodyContent}
                </TableBody>
            </Table>

            {seeMoreLink}
        </React.Fragment>
    );
}

export default DataTable;