import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import AppRoute from 'hoc/AppRoute/AppRoute';
import * as actions from '../../store/actions/index';
import Map from './Map/Map';
import ArrivalsList from './ArrivalsList/ArrivalsList';
import MainLayout from 'hoc/Layout/DesignLayout/MainLayout/MainLayout';

class Orders extends Component {

    componentDidMount() {
        if(this.props.isAdmin) {
            this.props.onFetchAdminOrders(this.props.token, this.props.compId);
        } else {
            if(this.props.trackingInfo.trackingId && !this.props.trackingInfo.error) {
                this.props.onFetchOrders(this.props.trackingInfo.trackingId); 
            } else {
                this.props.history.push('/tracking');
            }
        }
    }

    componentDidUpdate() {
        if(!this.props.loading && this.props.ordersLoaded && this.props.readyToLoad) {
            
            if(this.props.isAdmin) {
                setTimeout(() => {
                    if(this.props.isAuthenticated) {
                        this.props.onFetchAdminOrders(this.props.token, this.props.compId);
                    } else {
                        this.props.history.push('/admin');
                    }
                }, 300000);
            } else {
                setTimeout(() => {
                    if(this.props.trackingNumber) {
                        this.props.onFetchOrders(this.props.trackingNumber);
                    } else {
                        this.props.history.push('/tracking');
                    }
                }, 300000);
            }            
        }
    }

    shouldComponentUpdate ( nextProps, nextState ) {
        
        // if(this.props.loading !== nextProps.loading)
            return true;
        // else 
        //     return false;
    }

    render() {

        const appBasePath = (this.props.isAdmin)? "/admin" : "";

        return ( 
            <Switch>
                <AppRoute path={`${appBasePath}/orders/ankunft`} component={ArrivalsList} layout={MainLayout} />
                <AppRoute path={`${appBasePath}/orders/map`} component={Map} layout={MainLayout} />
                <Redirect to={`${appBasePath}/orders/ankunft`} />
            </Switch>
        );
    }
}



const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        isAdmin: state.auth.isAdmin,
        loading: state.order.loading,
        readyToLoad: state.order.readyToLoad,
        token: state.auth.token,
        compId: state.auth.compId,
        ordersLoaded: state.order.ordersLoaded,
        trackingInfo: state.auth.trackingInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchOrders: (trackid) => dispatch( actions.fetchOrders(trackid) ),
        onFetchAdminOrders: (authToken, compId) => dispatch( actions.fetchAdminOrders(authToken, compId) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(Orders);
