import React, { Component } from 'react';
import { connect } from 'react-redux';
import {serverApi} from 'axiosHelper';
import * as actions from 'store/actions/index';

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import {updateObject} from 'shared/utility';
import { withStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/containers/adminSettingsStyle';
import Snackbar from 'components/UI/Snackbar/Snackbar';

class AdminSettings extends Component {



    state = {
        changePasswordForm: {
            password: {
                variant: "outlined",
                margin:"normal",
                required: true,
                fullWidth: true,
                error: false,
                name:"password",
                label:"Passwort",
                type:"password",
                id:"password",
                autoComplete:"password",
                value:""
            },
            repeatPassword: {
                variant: "outlined",
                margin:"normal",
                error: false,
                required: true,
                fullWidth: true,
                name:"repeatPassword",
                label:"Passwort wiederholen",
                type:"password",
                id:"repeatPassword",
                autoComplete:"repeatPassword",
                value:""
            },

        },
        changePasswordCheck: false,
        themeColorsCheck: false,
        newFormChanged: false,
        colorFields: {
            primary: {
                value: this.props.themeColors.primary,
                error: false,
                helperText: "",
            },
            secondary: {
                value: this.props.themeColors.secondary,
                error: false,
                helperText: "",
            }
        },
        SnackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.themeColors !== this.state.themeColors) {
    //       this.setState({ themeColors: nextProps.themeColors });
    //     }
    // }

    onPasswordChanged = (event, type) => {

        const newPassVal = event.target.value;
        const updatedPasswordForm = {...this.state.changePasswordForm};
        let helperText = "";
        let isValid = true;
        if(type === "password") {
            helperText = "Das Passwort muss zwischen 6 und 12 Zeichen lang sein";
            isValid = newPassVal.length >= 6 && newPassVal.length <= 12;

        } else if(type === "repeatPassword") {
            helperText = "Passwort stimmt nicht überein";
            isValid = newPassVal === updatedPasswordForm.password.value;

        } 
        
        const updatedField = updateObject(updatedPasswordForm[type], {
            value: newPassVal,
            error: !isValid,
            helperText: isValid? "" : helperText
        });

        updatedPasswordForm[type] = updatedField;

        this.setState(updateObject(this.state,{
            changePasswordForm: updatedPasswordForm
        })); 
    }

    onColorChanged = (event) => {

        const newColorVal = event.target.value;
        const updatedColorFields = {...this.state.colorFields};

        const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

        const updatedField = updateObject(updatedColorFields[event.target.id], {
            value: newColorVal,
            error: !hexRegex.test(newColorVal),
            helperText: hexRegex.test(newColorVal)? "" : "Ungültiger Farbwert"
        });

        updatedColorFields[event.target.id] = updatedField;

        this.setState(updateObject(this.state,{
            colorFields: updatedColorFields
        })); 
    }

    passwordFormValidation = () => {
        const updatedPasswordForm = {...this.state.changePasswordForm};
        const passwordVal = updatedPasswordForm.password.value;
        const repeatPasswordVal = updatedPasswordForm.repeatPassword.value;
        if(passwordVal.length < 6 || passwordVal.length > 12) {
            const updatedField = updateObject(updatedPasswordForm.password, {
                error: true,
                helperText: "Das Passwort muss zwischen 6 und 12 Zeichen lang sein"
            });
    
            updatedPasswordForm.password = updatedField;
    
            this.setState(updateObject(this.state,{
                changePasswordForm: updatedPasswordForm
            }));
            return false;
        } else if(passwordVal !== repeatPasswordVal) {
            const updatedField = updateObject(updatedPasswordForm.repeatPassword, {
                error: true,
                helperText: "Passwort stimmt nicht überein"
            });
    
            updatedPasswordForm.repeatPassword = updatedField;
    
            this.setState(updateObject(this.state,{
                changePasswordForm: updatedPasswordForm
            }));
            return false;
        } else {
            return true;
        }
    }

    themeColorValidation = () => {
        const updatedColorForm = {...this.state.colorFields};
        const primColor = updatedColorForm.primary.value;
        const secColor = updatedColorForm.secondary.value;
        const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

        if(hexRegex.test(primColor) && hexRegex.test(secColor)) {
            return true;
        } else {
            const updatedPrimField = updateObject(updatedColorForm.primary, {
                error: !hexRegex.test(primColor),
                helperText: hexRegex.test(primColor)? "" : "Ungültiger Farbwert"
            });
            const updatedSecField = updateObject(updatedColorForm.secondary, {
                error: !hexRegex.test(secColor),
                helperText: hexRegex.test(secColor)? "" : "Ungültiger Farbwert"
            });
            this.setState(updateObject(this.state,{
                colorFields: {
                    primary: updatedPrimField,
                    secondary: updatedSecField
                }
            })); 
            return false;
        }
    }

    themeColorsSwithcHandler = () => {
        this.setState(prevState => {
            const updatedCheck = !prevState.themeColorsCheck;
            return {
                themeColorsCheck: updatedCheck,
                newFormChanged: prevState.newFormChanged || updatedCheck
            }
        });
    }

    passwordChangeSwitchHandler = () => {
        this.setState(prevState => {
            const updatedCheck = !prevState.changePasswordCheck;
            return {
                changePasswordCheck: updatedCheck,
                newFormChanged: prevState.newFormChanged || updatedCheck
            }
        });
    }

    SnackbarClosedHandler = () => {
        this.setState({
            SnackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }
    
    colorPickerClickedHandler = (type) => {
        this.setState(prevState => {
            const updatedCheck = !prevState.themeColorsCheck;
            return {
                themeColorsCheck: updatedCheck,
                newFormChanged: prevState.newFormChanged || updatedCheck
            }
        });
    }

    onSaveSettings = () => {
        let isFormValid = true;
        let isFormChanged = false;
        let payload = {
            userId: this.props.compId,
            changePassword: false,
            changeColors: false
        };

        const {password, repeatPassword} = this.state.changePasswordForm;
        const {primary, secondary} = this.state.colorFields;

        if(this.state.changePasswordCheck) {
            isFormChanged = true;
            if(password.error || repeatPassword.error || !this.passwordFormValidation()) {
                isFormValid = false;
            } else {
                Object.assign(payload, {
                    changePassword: true,
                    password: password.value
                });
            }
        }

        if(this.state.themeColorsCheck) {
            isFormChanged = true;
            if(primary.error || secondary.error || !this.themeColorValidation()) {
                isFormValid = false;
            } else {
                Object.assign(payload, {
                    changeColors: true,
                    primary: primary.value,
                    secondary: secondary.value
                });
            }
        }
        if(isFormValid && isFormChanged) {

            const queryParam = "?authToken=" + this.props.token;

            serverApi.put( `/company/adminsettings${queryParam}`, payload)
            .then( response => {
                if(response.data.success) {
                    if(this.state.themeColorsCheck) {
                        setTimeout(() => {
                            this.setState({
                                SnackbarAlert: {
                                    status: true,
                                    alertType: 'success',
                                    message: "Nach dem Neuladen werden neue Themenfarben angewendet"
                                }
                            });
                        }, 1000);
                    }

                    if(this.state.changePasswordCheck)
                        this.passwordChangeSwitchHandler();
                    if(this.state.themeColorsCheck)
                        this.themeColorsSwithcHandler();
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Einstellungen erfolgreich gespeichert!"
                        }
                    });
                } else {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    SnackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: "Aufgabe konnte nicht abgeschlossen werden. Bitte versuch es später!"
                    }
                });
            } );
        } else {
            return false;
        }
    }

    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.SnackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.SnackbarAlert.alertType}
                handleClose={this.SnackbarClosedHandler}>
                {this.state.SnackbarAlert.message}
            </Snackbar>
        ): null;

        const textFieldComp = this.state.changePasswordCheck ? Object.keys(this.state.changePasswordForm)
                                .map(fieldKey => 
                                    React.cloneElement(
                                        <TextField 
                                            key = {fieldKey}
                                            onChange={(event) => {this.onPasswordChanged(event, fieldKey)}} />,
                                        {...this.state.changePasswordForm[fieldKey]}
                                )) : null;

        const colorPickerFields = this.state.themeColorsCheck ? (
            <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="stretch">
                <Divider />
                <Grid item>&nbsp;</Grid>
                <Grid item>&nbsp;</Grid>

                <Grid 
                    component="label" 
                    container  
                    direction="row"
                    justify="space-between"
                    alignItems="stretch">
                    <Grid item>                        
                        <TextField
                            id="primary"
                            label="Primärfarbe"
                            variant="outlined"
                            color="primary"
                            error={this.state.colorFields.primary.error}
                            helperText={this.state.colorFields.primary.helperText}
                            value={this.state.colorFields.primary.value}
                            onChange={this.onColorChanged}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            name="primary"
                            style={{backgroundColor: this.state.colorFields.primary.value}}>
                            Primärfarbe
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>&nbsp;</Grid>

                <Grid 
                    component="label" 
                    container  
                    direction="row"  
                    justify="space-between"  
                    alignItems="center">
                    <Grid item>                        
                        <TextField
                            id="secondary"
                            label="Sekundärfarbe"
                            variant="outlined"
                            color="secondary"
                            error={this.state.colorFields.secondary.error}
                            helperText={this.state.colorFields.secondary.helperText}
                            value={this.state.colorFields.secondary.value}
                            onChange={this.onColorChanged}
                        />
                    </Grid>
                    <Grid item>
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            name="secondary"
                            style={{backgroundColor: this.state.colorFields.secondary.value}}>
                            Sekundärfarbe
                        </Button>
                    </Grid>
                </Grid>
                
                <Grid item>&nbsp;</Grid>
                <Grid item>&nbsp;</Grid>
                <Typography variant="caption" display="block" gutterBottom>
                    Klicken Sie <Link target="_blank" href={`https://material.io/resources/color`}>hier</Link>, um die Farbpalette zu öffnen und die besten Farbkombinationen zu erstellen.
                </Typography>
                
            </Grid>
            ) : null;
        
        return (
            <React.Fragment>
                <CssBaseline />
                <Container component="main" maxWidth="sm">
                    <div>
                        {/* <Typography component="h1" variant="h5">
                            Settings
                        </Typography> */}
                        <form className={classes.form} noValidate onSubmit={this.onLoginHandler}>
                            
                            <Paper elevation={3} className={classes.formGroupPaper}>
                                <Grid 
                                    component="label" 
                                    container  
                                    direction="row"  
                                    justify="space-between"  
                                    alignItems="center">
                                    <Grid item>
                                        <Typography component="h5">
                                            Passwort ändern
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch 
                                            checked={this.state.changePasswordCheck} 
                                            onChange={this.passwordChangeSwitchHandler}
                                            color="primary" />
                                    </Grid>
                                </Grid>
                                {textFieldComp}
                            </Paper>

                            
                            <Paper elevation={3} className={classes.formGroupPaper}>
                                <Grid 
                                    component="label" 
                                    container  
                                    direction="row"  
                                    justify="space-between"  
                                    alignItems="center">
                                    <Grid item>
                                        <Typography component="h5">
                                            Themenfarben
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch 
                                            checked={this.state.themeColorsCheck} 
                                            onChange={this.themeColorsSwithcHandler}
                                            color="primary" />
                                    </Grid>
                                </Grid>
                                {colorPickerFields}
                            </Paper>

                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.onSaveSettings}
                                disabled = {!this.state.newFormChanged && !this.state.themeColorsCheck}
                                className={classes.submit}
                            >
                                speichern
                            </Button>
                        </form>
                        {alertSnackBar}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        compId: state.auth.compId,
        userName: state.auth.userName,
        token: state.auth.token,
        appType: state.auth.appType,
        themeColors: state.auth.themeColors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetThemeColors: (themeColors, isDefault) => dispatch( actions.setThemeColors(themeColors, isDefault) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(AdminSettings) );