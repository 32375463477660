
import React from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import { NavListPrimary, NavListSecondary } from './NavigationItems/NavigationItems';
import styles from 'assets/jss/components/drawerStyle';

const useStyles = makeStyles(styles);


const NavDrawer = ( props ) => {
    const classes = useStyles();

    const secondaryNav = props.isAuthenticated ? (
        <React.Fragment>
            <Divider />
            <List>
                <div>
                    <ListSubheader inset>Admin Kontrollen</ListSubheader>
                    <NavListSecondary 
                        appType= {props.appType}
                        activePath={props.activePath}
                        isAuthenticated = {props.isAuthenticated} 
                        clicked = {props.linkClicked} />
                </div>
            </List>
        </React.Fragment>
    ): null;

    return (
        <Drawer
            variant="permanent"
            classes={{
            paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            open={props.open} >
            <div className={classes.toolbarIcon}>
            <IconButton onClick={props.closeClicked}>
                <ChevronLeftIcon />
            </IconButton>
            </div>
            <Divider />
            <List>
                <NavListPrimary  
                    appType= {props.appType}
                    activePath={props.activePath}
                    isAuthenticated = {props.isAuthenticated} 
                    clicked = {props.linkClicked} />
            </List>
            {secondaryNav}
        </Drawer>
    );
};


export default NavDrawer;