import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Snackbar from '../../components/UI/Snackbar/Snackbar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import FileCopyIcon from '@material-ui/icons/FileCopy';
// core components
import styles from "../../assets/jss/components/trackingIdsTableStyle";
import ChipsContainer from '../../components/UI/ChipsContainer/ChipsContainer';

const useStyles = makeStyles(styles);

const TrackingIdsTable = (props) => {
    const classes = useStyles();            

    const linkCopySnackBar = props.linkCopySuccess? (
        <Snackbar 
            opened={true} 
            alertType= "success"
            handleClose= {props.linkCopyClosed}>
            Der Link wurde kopiert!
        </Snackbar>
    ): null;

    
    let headerCells = null;
    headerCells = props.columnConfig.map( column => {
                    let cellContent = column.title;
                    if(column.title.length > 1 && column.field.length > 1) {
                        if(column.field === props.sortBy.field) {
                            cellContent = (
                                <TableCell 
                                    className={`${classes.tableCellHeader} ${classes.tableCellHeaderSorter}`} 
                                    key = {column.field}
                                    onClick={() => props.sortHandle(column.field, props.sortBy.ascOrder)}>
                                    {props.sortBy.ascOrder?(<ArrowUpwardIcon />): (<ArrowDownwardIcon />)}
                                    <Typography>
                                        {column.title}
                                    </Typography>
                                </TableCell>
                            );
                        } else {
                            cellContent = (
                                <TableCell
                                    className={`${classes.tableCellHeader}`} 
                                    key = {column.field}
                                    onClick={() => props.sortHandle(column.field)}>
                                    <Typography >
                                        {column.title}
                                    </Typography>
                                </TableCell>
                            );
                        }
                        return cellContent;
                    } else {
                        return (
                            <TableCell key = {cellContent}>
                                {cellContent}
                            </TableCell>
                        );
                    }
                    
                });

                

    const sortedTrackingIds = props.trackingIdsList.sort((a, b) => {
        if(props.sortBy.ascOrder) {
            return (a[props.sortBy.field] > b[props.sortBy.field]) ? 1 : -1;
        } else {
            return (a[props.sortBy.field] < b[props.sortBy.field]) ? 1 : -1;
        }   
    });

    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        {headerCells}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedTrackingIds.map((trackingId, index) => (
                    <TableRow key={index} >

                        <TableCell >
                            <Typography>{trackingId.reference}</Typography>
                        </TableCell>

                        <TableCell >
                            
                            <ChipsContainer
                                disabled
                                keywords={trackingId.keywords} />
                        </TableCell>

                        <TableCell >
                            <a href={"https://" + window.location.host + "/tracking?trackid=" + trackingId.tracking_id} rel="noopener noreferrer" target="_blank">
                                {"https://" + window.location.host + "/tracking?trackid=" + trackingId.tracking_id}
                            </a>
                        </TableCell>

                        <TableCell>
                            <Tooltip
                                id="tooltip-top"
                                title="Kopieren Sie den Link"
                                placement="top">
                                <IconButton
                                    aria-label="Kopieren"
                                    onClick={event => props.hanleCopy(event, trackingId.tracking_id)}
                                    className={classes.tableActionButton}>
                                <FileCopyIcon
                                    className={
                                    classes.tableActionButtonIcon
                                    }
                                />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                id="tooltip-top"
                                title="Track-ID bearbeiten"
                                placement="top" >
                                <IconButton
                                aria-label="bearbeiten"
                                className={classes.tableActionButton}
                                onClick= {(event) => props.handleEdit(event, trackingId)} >
                                <Edit
                                    className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                    }
                                />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                id="tooltip-top-start"
                                title="Tracking-ID löschen"
                                placement="top" >
                                <IconButton
                                aria-label="löschen"
                                className={classes.tableActionButton} 
                                onClick= {(event) => props.hanleDelete(trackingId.tracking_id)} >
                                <Close
                                    className={
                                    classes.tableActionButtonIcon + " " + classes.close
                                    }
                                />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            {linkCopySnackBar}
        </React.Fragment>
    );
}

TrackingIdsTable.propTypes = {
};

export default TrackingIdsTable;
