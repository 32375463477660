import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';


const DialogContainer = (props) => {
  const { onClose, open, ...other } = props;

  React.useEffect(() => {
  }, [open]);

  const handleEntering = () => {
    // TODO
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose("Some Value");
  };

  const dialogActions = props.showActions ? (
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                Ok
                </Button>
            </DialogActions>) : null;

  return (
        <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth={true}
        onEntering={handleEntering}
        open={open}
        onClose={onClose}
        {...other}
        >

            <DialogContent dividers>
                {props.children}
            </DialogContent>
            
            {dialogActions}
        
        </Dialog>
  );
}

DialogContainer.propTypes = {
};

export default DialogContainer;
