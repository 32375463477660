import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import QueueIcon from '@material-ui/icons/Queue';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const ChipTextInput = (props) => {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <InputBase
                fullWidth
                className={classes.input}
                placeholder={props.placeholder}
                inputProps={{...props.inputProps}}
                onChange={props.inputChanged}
                value={props.keyword}
                />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton 
              color="primary" 
              className={classes.iconButton} 
              aria-label="add-keyword" 
              onClick={props.handleInsert} 
              disabled={!props.isValid}>
                <QueueIcon />
            </IconButton>
        </Paper>
    );
}

export default ChipTextInput;