import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'store/actions/index';

import Auth from 'containers/Auth/Auth';
import PasswordGenerate from 'containers/PasswordGenerate/PasswordGenerate';
import AdminSettings from 'containers/AdminSettings/AdminSettings';
import TrackingID from 'containers/TrackingID/TrackingID';
import Orders from 'containers/Orders/Orders';
import Logout from 'containers/Auth/Logout/Logout';
import AppRoute from 'hoc/AppRoute/AppRoute';
import MainLayout from 'hoc/Layout/DesignLayout/MainLayout/MainLayout';

class AdminApp extends Component {

    constructor(props) {
        super(props);
        this.props.onSetAdmin(true, "admin");
        this.props.onSetAuthRedirectPath('/admin');
    }

    componentDidMount() {
        if(!this.props.isAuthenticated) {
            this.props.onTryAutoSignup(true); 
        }           
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/admin/auth" component={Auth} />
                <Route path="/admin/password" component={PasswordGenerate} />
                <Redirect to="/admin/auth" />
            </Switch>
        );
        

        if ( this.props.isAuthenticated ) {
            routes = (
                <Switch>
                    <Route path="/admin/orders" component={Orders} />
                    <AppRoute path="/admin/trackingid" component={TrackingID} layout={MainLayout} />
                    <Route path="/admin/logout" component={Logout} />
                    <AppRoute path="/admin/settings" component={AdminSettings} layout={MainLayout}  />
                    <Route path="/admin/auth" component={Auth} />
                    <Redirect to="/admin/orders" />
                </Switch>
            );
        }
        
        
        return (
            <React.Fragment>
                {routes}
            </React.Fragment>
            
        );
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAdmin: (isAdmin, appType) => dispatch( actions.setAdmin(isAdmin, appType) ),
        onSetAuthRedirectPath: (path) => dispatch( actions.setAuthRedirectPath(path) ),
        onTryAutoSignup: (isAdmin) => dispatch( actions.authCheckState(isAdmin) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(AdminApp);