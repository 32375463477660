import React, { Component } from 'react';
import { connect } from 'react-redux';

import MapWrapped from 'components/MapWrapped/MapWrapped';
import styles from './OrdersMap.module.css';

class Map extends Component {

    

    shouldComponentUpdate(nextProps, nextState) {
        // Component update when loading is done. 
        return this.props.orders !== nextProps.orders;
    }


    render() {
        
        const filteredOrdersList = this.props.orders.filter(order => {
            let shouldReturn = (this.props.filters.statusList.includes(+order.order_status) && [31,50].includes(+order.order_status) );

            const searchString = this.props.filters.searchString.trim();
            if(shouldReturn && searchString !== '') {
                
                shouldReturn = shouldReturn && Object.keys(order).some(key => {
                    try {
                        return order[key].toString().toLowerCase().includes(searchString);
                    } catch (error) {
                        return false;
                    }
                });
            }

            return shouldReturn;
        });

        return (
            <div className={styles.OrdersMap}>
              <MapWrapped orders={filteredOrdersList} />
            </div>
          );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.order.orders,
        filters: state.order.filters,
        loading: state.order.loading
    };
};

export default connect( mapStateToProps)( Map );