import * as actionTypes from '../actions/actionTypes';
import { updateObject, insertItem, removeItem } from '../../shared/utility';
import { addSeconds, formatRelative } from 'date-fns';
import { de } from 'date-fns/locale';

const initialState = {
    orders: [],
    statusCount: {
        31: 0, 32: 0, 50: 0, 51: 0 
    },
    columnFilters: ["order_status", "client", "vehicle_id", "order_id", "planned_arrival", "estimated_arrival", "estimated_distance"],
    filters: {
        statusList: [31,32,50,51],
        searchString: '',

    },
    lastUpdate: null,
    ordersLoaded: false,
    distanceLoading: false,
    loading: true,
    distanceOffset:0,
    distanceLimit:3,
    readyToLoad: true,
    error: null,
};

const searchStringFilterUpdate = ( state, action ) => {
    const updatedFilters = updateObject(state.filters, {
        searchString: action.searchString
    });

    return updateObject( state, {
        filters: updatedFilters
    } );
};

const statusFiltersUpdate = ( state, action ) => {
    const statusIndex = state.filters.statusList.indexOf(action.filterStatus);
    const updatedArray =  (statusIndex !== -1)?
                            removeItem(state.filters.statusList, {item: action.filterStatus, index: statusIndex}):
                            insertItem(state.filters.statusList, {item: action.filterStatus});
    const updatedFilters = updateObject(state.filters, {
        statusList: updatedArray
    });

    return updateObject( state, {
        filters: updatedFilters
    } );
};

const fetchOrdersStart = ( state, action ) => {
    return updateObject( state, { 
        loading: true
    } );
};

const fetchOrdersSuccess = ( state, action ) => {
    const updatedColumnFilter = action.columnFilters? action.columnFilters: state.columnFilters;
    return updateObject( state, {
        orders: action.orders,
        statusCount: action.orderStatusCount,
        columnFilters: updatedColumnFilter,
        ordersLoaded: true,
        distanceLoading: true,
        distanceOffset:0,
        lastUpdate: new Date(),
        error: null
    } );
};

const fetchOrdersFail = ( state, action ) => {
    return updateObject( state, { loading: false, readyToLoad: action.readyToLoad, error:action.error } );
};

const fetchOrderDistancesSuccess = ( state, action ) => {
    return updateObject( state, {
        distanceLoading: false,
        loading:false 
    } );
};

const fetchOrderDistancesFail = ( state, action ) => {
    console.warn('[FETCH_ORDERS_DISTANCES_FAIL]', 'TODO: Handle errors');
    return state;
    // return updateObject( state, { loading: false } );
};

const fetchOrderDistancesUpdate = ( state, action ) => {

    const updatedOrders = state.orders.slice();
    action.distances.forEach(distance => {
        const modOrderValues = {
            estimated_arrival_orig: distance.distance_values.estimated_arrival,
            estimated_arrival: formatRelative(addSeconds(new Date(), distance.distance_values.estimated_arrival), new Date(), {locale: de}),
            estimated_distance_orig: distance.distance_values.estimated_distance,
            estimated_distance: +(distance.distance_values.estimated_distance / 1000).toFixed(1)
        }
        updatedOrders[distance.order_index] = {
            ...updatedOrders[distance.order_index],
            ...modOrderValues
        }
    });
    return updateObject( state, {
        orders: updatedOrders,
        distanceOffset: action.offset
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FILTERS_UPDATE_SEARCH_STRING: return searchStringFilterUpdate( state, action );
        case actionTypes.FILTERS_UPDATE_STATUS_LIST: return statusFiltersUpdate( state, action );
        case actionTypes.FETCH_ORDERS_START: return fetchOrdersStart( state, action );
        case actionTypes.FETCH_ORDERS_SUCCESS: return fetchOrdersSuccess( state, action );
        case actionTypes.FETCH_ORDERS_FAIL: return fetchOrdersFail( state, action );
        
        case actionTypes.FETCH_ORDERS_DISTANCES_SUCCESS: return fetchOrderDistancesSuccess( state, action );
        case actionTypes.FETCH_ORDERS_DISTANCES_FAIL: return fetchOrderDistancesFail( state, action );
        case actionTypes.FETCH_ORDERS_DISTANCES_UPDATE: return fetchOrderDistancesUpdate( state, action );
        default: return state;
    }
};

export default reducer;