import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'store/actions/index';

class Logout extends Component {
    componentDidMount () {
        this.props.onLogout(this.props.isAdmin);
    }

    render () {
        return <Redirect to={this.props.authRedirectPath} />;
    }
}


const mapStateToProps = state => {
    return {
        isAdmin: state.auth.isAdmin,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (isAdmin) => dispatch(actions.logout(isAdmin))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);