import React, { Component } from 'react';
import { connect } from 'react-redux';
import {serverApi} from 'axiosHelper';

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import {updateObject} from 'shared/utility';
import { withStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/containers/adminSettingsStyle';
import Snackbar from 'components/UI/Snackbar/Snackbar';

class SupportSettings extends Component {

    state = {
        changePasswordForm: {
            password: {
                variant: "outlined",
                margin:"normal",
                required: true,
                fullWidth: true,
                error: false,
                name:"password",
                label:"Passwort",
                type:"password",
                id:"password",
                autoComplete:"password",
                value:""
            },
            repeatPassword: {
                variant: "outlined",
                margin:"normal",
                error: false,
                required: true,
                fullWidth: true,
                name:"repeatPassword",
                label:"Passwort wiederholen",
                type:"password",
                id:"repeatPassword",
                autoComplete:"repeatPassword",
                value:""
            },

        },
        changePasswordCheck: false,
        newFormChanged: false,
        SnackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }
    

    onPasswordChanged = (event, type) => {

        const newPassVal = event.target.value;
        const updatedPasswordForm = {...this.state.changePasswordForm};
        let helperText = "";
        let isValid = true;
        if(type === "password") {
            helperText = "Das Passwort muss zwischen 6 und 12 Zeichen lang sein";
            isValid = newPassVal.length >= 6 && newPassVal.length <= 12;

        } else if(type === "repeatPassword") {
            helperText = "Passwort stimmt nicht überein";
            isValid = newPassVal === updatedPasswordForm.password.value;

        } 
        
        const updatedField = updateObject(updatedPasswordForm[type], {
            value: newPassVal,
            error: !isValid,
            helperText: isValid? "" : helperText
        });

        updatedPasswordForm[type] = updatedField;

        this.setState(updateObject(this.state,{
            changePasswordForm: updatedPasswordForm
        })); 
    }

    passwordFormValidation = () => {
        const updatedPasswordForm = {...this.state.changePasswordForm};
        const passwordVal = updatedPasswordForm.password.value;
        const repeatPasswordVal = updatedPasswordForm.repeatPassword.value;
        if(passwordVal.length < 6 || passwordVal.length > 12) {
            const updatedField = updateObject(updatedPasswordForm.password, {
                error: true,
                helperText: "Das Passwort muss zwischen 6 und 12 Zeichen lang sein"
            });
    
            updatedPasswordForm.password = updatedField;
    
            this.setState(updateObject(this.state,{
                changePasswordForm: updatedPasswordForm
            }));
            return false;
        } else if(passwordVal !== repeatPasswordVal) {
            const updatedField = updateObject(updatedPasswordForm.repeatPassword, {
                error: true,
                helperText: "Passwort stimmt nicht überein"
            });
    
            updatedPasswordForm.repeatPassword = updatedField;
    
            this.setState(updateObject(this.state,{
                changePasswordForm: updatedPasswordForm
            }));
            return false;
        } else {
            return true;
        }
    }

    passwordChangeSwitchHandler = () => {
        this.setState(prevState => {
            const updatedCheck = !prevState.changePasswordCheck;
            return {
                changePasswordCheck: updatedCheck,
                newFormChanged: updatedCheck
            }
        });
    }

    SnackbarClosedHandler = () => {
        this.setState({
            SnackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    onSaveSettings = () => {
        const {password, repeatPassword} = this.state.changePasswordForm;
        if(password.error || repeatPassword.error || !this.passwordFormValidation()) {
            return false;
        } else {
            //submit
            const authData = {
                userId: this.props.appType === "admin" ? this.props.compId : this.props.userName,
                password: password.value
            };
            const queryParam = "?authToken=" + this.props.token;

            const endPointType = this.props.appType === "admin" ? "company" : "support";

            serverApi.put( `/${endPointType}/changepassword${queryParam}`, authData)
            .then( response => {
                if(response.data.success) {
                    this.passwordChangeSwitchHandler();
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Passwort geändert!"
                        }
                    });
                } else {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    SnackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: "Aufgabe konnte nicht abgeschlossen werden. Bitte versuch es später!"
                    }
                });
            } );
        }
    }

    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.SnackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.SnackbarAlert.alertType}
                handleClose={this.SnackbarClosedHandler}>
                {this.state.SnackbarAlert.message}
            </Snackbar>
        ): null;

        const textFieldComp = this.state.changePasswordCheck ? Object.keys(this.state.changePasswordForm)
                                .map(fieldKey => 
                                    React.cloneElement(
                                        <TextField 
                                            key = {fieldKey}
                                            onChange={(event) => {this.onPasswordChanged(event, fieldKey)}} />,
                                        {...this.state.changePasswordForm[fieldKey]}
                                )) : null;
        
        return (
            <React.Fragment>
                <CssBaseline />
                <Container component="main" maxWidth="sm">
                    <div>
                        {/* <Typography component="h1" variant="h5">
                            Settings
                        </Typography> */}
                        <form className={classes.form} noValidate onSubmit={this.onLoginHandler}>
                            
                            <Paper elevation={3} className={classes.formGroupPaper}>
                                <Grid 
                                    component="label" 
                                    container  
                                    direction="row"  
                                    justify="space-between"  
                                    alignItems="center">
                                    <Grid item>
                                        <Typography component="h5">
                                            Passwort ändern
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch 
                                            checked={this.state.changePasswordCheck} 
                                            onChange={this.passwordChangeSwitchHandler}
                                            color="primary" />
                                    </Grid>
                                </Grid>
                                {textFieldComp}
                            </Paper>

                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.onSaveSettings}
                                disabled = {!this.state.newFormChanged}
                                className={classes.submit}
                            >
                                speichern
                            </Button>
                        </form>
                        {alertSnackBar}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        compId: state.auth.compId,
        userName: state.auth.userName,
        token: state.auth.token,
        appType: state.auth.appType
    };
};

export default connect( mapStateToProps )( withStyles(styles, {withTheme: true})(SupportSettings) );