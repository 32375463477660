import {dangerColor, warningColor, infoColor, successColor} from '../assets/jss/appStyle';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const insertItem = (array, action) => {
    let newArray = array.slice();
    newArray.splice(action.index, 0, action.item);
    return newArray;
};

export const replaceItem = (array, action) => {
    let newArray = array.slice();
    newArray.splice(action.index, 1, action.item);
    return newArray;
};

export const removeItem = (array, action) => {
    let newArray = array.slice();
    newArray.splice(action.index, 1);
    return newArray;
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
};

export const checkWhiteSpaces = (string) => {
    return /\s/g.test(string);
}

export const formateOrderStatus = order => {
    const statusCaptions = {
        31: {title:"unterbrochen", colorClass:warningColor[0]}, 
        32: {title:"abgebrochen", colorClass:dangerColor[0]}, 
        50: {title:order.estimated_arrival, colorClass:infoColor[0]}, 
        51: {title:"angekommen", colorClass:successColor[0]}
    };
    return statusCaptions[order.order_status];
}

