import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import { primaryColor } from "assets/jss/appStyle";

import {navigationRoutes} from 'routes';

const NavListPrimary = (props) => {
    const routes = navigationRoutes[props.appType].primary;
    const listItems = routes.map((route, key) => {

        return (
            <ListItem button key={key} onClick={(event)=>{props.clicked(event, route.path, route.path === props.activePath)}}>
                <ListItemIcon>
                    {
                        <route.icon  color={route.path === props.activePath?'primary': 'inherit'} />
                    }
                </ListItemIcon>
                <ListItemText 
                    primary={route.name} 
                    style={route.path === props.activePath?{color: primaryColor[1], textDecoration:'underline'}: null} />
            </ListItem>
        );
    });
    return (
        <div>
            {listItems}
        </div>
    );
};



const NavListSecondary = (props) => {
    
    const routes = navigationRoutes[props.appType].secondary;
    const listItems = routes.map((route, key) => {
        return (

            <ListItem button key={key} onClick={(event)=>{props.clicked(event, route.path, route.path === props.activePath)}}>
                <ListItemIcon>
                    {
                        typeof route.icon === "string" ? (
                            <Icon> {route.icon} </Icon>
                        ) : (
                            <route.icon />
                        )
                    }
                </ListItemIcon>
                <ListItemText primary={route.name} />
            </ListItem>
        );
    });
    return (
        <div>
            {listItems}
        </div>
    );
};


export {NavListSecondary , NavListPrimary};

// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Admin Controls</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <LinkIcon />
//       </ListItemIcon>
//       <ListItemText primary="Manage Links" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <ExitToAppIcon />
//       </ListItemIcon>
//       <ListItemText primary="Logout" />
//     </ListItem>
//   </div>
// );
