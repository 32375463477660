
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const FETCH_ORDERS_DISTANCES_SUCCESS = 'FETCH_ORDERS_DISTANCES_SUCCESS';
export const FETCH_ORDERS_DISTANCES_UPDATE = 'FETCH_ORDERS_DISTANCES_UPDATE';
export const FETCH_ORDERS_DISTANCES_FAIL = 'FETCH_ORDERS_DISTANCES_FAIL';

export const FETCH_TRACKING_IDS_START = 'FETCH_TRACKING_IDS_START';
export const FETCH_TRACKING_IDS_SUCCESS = 'FETCH_TRACKING_IDS_SUCCESS';
export const FETCH_TRACKING_IDS_FAIL = 'FETCH_TRACKING_IDS_FAIL';

export const FILTERS_UPDATE_STATUS_LIST = 'FILTERS_UPDATE_STATUS_LIST';
export const FILTERS_UPDATE_SEARCH_STRING = 'FILTERS_UPDATE_SEARCH_STRING';

export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';

export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';


export const SET_TRACKING_INFO_FAIL = 'SET_TRACKING_INFO_FAIL';
export const SET_TRACKING_INFO = 'SET_TRACKING_INFO';
export const SET_TRACKING_NUMBER = 'SET_TRACKING_NUMBER';

export const SET_ADMIN = 'SET_ADMIN';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const START_THEME_COLORS = 'START_THEME_COLORS';
export const SET_THEME_COLORS = 'SET_THEME_COLORS';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';