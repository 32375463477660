import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Reset from '@material-ui/icons/RotateLeft';
// core components
import styles from "assets/jss/components/trackingIdsTableStyle";

const useStyles = makeStyles(styles);

const CustomersTable = (props) => {
    const classes = useStyles();
    let headerCells = null;
    headerCells = props.columnConfig.map( column => {
                    let cellContent = column.title;
                    if(column.field === props.sortBy.field) {
                        cellContent = (
                            <TableCell 
                                className={`${classes.tableCellHeader} ${classes.tableCellHeaderSorter}`} 
                                key = {column.field}
                                onClick={() => props.sortHandle(column.field, props.sortBy.ascOrder)}>
                                {props.sortBy.ascOrder?(<ArrowUpwardIcon />): (<ArrowDownwardIcon />)}
                                <Typography>
                                    {column.title}
                                </Typography>
                            </TableCell>
                        );
                    } else {
                        cellContent = (
                            <TableCell
                                className={`${classes.tableCellHeader}`} 
                                key = {column.field}
                                onClick={() => props.sortHandle(column.field)}>
                                <Typography >
                                    {column.title}
                                </Typography>
                            </TableCell>
                        );
                    }
                    
                    return cellContent;
                });

    

    const sortedCustomers = props.customers.sort((a, b) => {
        if(props.sortBy.ascOrder) {
            return (a[props.sortBy.field] > b[props.sortBy.field]) ? 1 : -1;
        } else {
            return (a[props.sortBy.field] < b[props.sortBy.field]) ? 1 : -1;
        }   
    });
    
    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        {headerCells}
                        <TableCell align="right">Aktion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedCustomers.map((customer, index) => (
                    <TableRow key={index} >

                        <TableCell >
                            <Typography>{customer.COMP_ID}</Typography>
                        </TableCell>

                        <TableCell >
                            <Typography>{customer.NAME1}</Typography>
                        </TableCell>

                        <TableCell >
                            <Typography>{customer.COMP_EMAIL}</Typography>
                        </TableCell>

                        <TableCell >
                            <Typography>{customer.SUB_DOMAIN}</Typography>
                        </TableCell>
                        
                        <TableCell align="right">

                            <Tooltip
                                id="tooltip-top"
                                title="Passwort zurücksetzen"
                                placement="top" >
                                <IconButton
                                aria-label="Passwort zurücksetzen"
                                className={classes.tableActionButton}
                                onClick= {(event) => props.handleResetPassword(customer.COMP_ID)} >
                                <Reset
                                    className={
                                    classes.tableActionButtonIcon + " " + classes.reset
                                    }
                                />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                id="tooltip-top"
                                title="Kunden bearbeiten"
                                placement="top" >
                                <IconButton
                                aria-label="bearbeiten"
                                className={classes.tableActionButton}
                                onClick= {(event) => props.handleEdit(event, customer)} >
                                <Edit
                                    className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                    }
                                />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                id="tooltip-top-start"
                                title="Kunden löschen"
                                placement="top" >
                                <IconButton
                                aria-label="löschen"
                                className={classes.tableActionButton} 
                                onClick= {(event) => props.hanleDelete(customer.COMP_ID)} >
                                <Close
                                    className={
                                    classes.tableActionButtonIcon + " " + classes.close
                                    }
                                />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

CustomersTable.propTypes = {
};

export default CustomersTable;
