import * as actionTypes from './actionTypes';
import {serverApi} from '../../axiosHelper';

// Actions for fetching tracking ids list
export const fetchTrackingIdsStart = () => {
    return {
        type: actionTypes.FETCH_TRACKING_IDS_START
    };
};

export const fetchTrackingIdsSuccess = ( trackingIdsList ) => {
    return {
        type: actionTypes.FETCH_TRACKING_IDS_SUCCESS,
        trackingIds: trackingIdsList
    };
};

export const fetchTrackingIdsFailed = (errorMsg) => {
    return {
        type: actionTypes.FETCH_TRACKING_IDS_FAIL,
        error: errorMsg
    };
};

export const fetchTrackingIds = (compId, authToken) => {
    return dispatch => {
        dispatch( fetchTrackingIdsStart() );
        const queryParams = 'authToken=' + authToken + '&compid=' + compId;
        serverApi.get( '/trackingid?' + queryParams )
            .then( response => {
                if(response.data.success) {
                    dispatch(fetchTrackingIdsSuccess(response.data.rows));
                }
                else {
                    dispatch(fetchTrackingIdsFailed(response.data.error));
                }
            } )
            .catch( error => {
                dispatch(fetchTrackingIdsFailed(error.message));
            } );
    };
};