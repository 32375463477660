import React, { Component } from 'react';
import { connect } from 'react-redux';
import {serverApi} from 'axiosHelper';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import AddCircleIcon from '@material-ui/icons/AddCircle';

// core components
import TrackingIdsTable from 'components/TrackingIdsTable/TrackingIdsTable';
import Dialog from 'components/UI/DialogContainer/DialogContainer';
import TrackingIdsForm from './TrackingIdsForm/TrackingIdsForm';
import styles from 'assets/jss/containers/trackingIdManagerStyle';
import Snackbar from 'components/UI/Snackbar/Snackbar';
import * as actions from 'store/actions/index';
import { updateObject } from 'shared/utility';

class TrackingID extends Component {

    state = {
        addNewDialogOpen : false,
        linkCopySuccess: false,
        editTrackingId: null,
        isEditDialog: false,
        SnackbarAlert: {
            status: false,
            alertType: null,
            message: null
        },
        columnConfig: [
            {
                field: 'reference',
                title: 'Referenz'
            },
            {
                field: '',
                title: 'Filter Kriterien'
            },
            {
                field: 'tracking_id',
                title: 'Sendungsnummer'
            },
            {
                field: '',
                title: 'Aktion'
            },
        ],
        tableSortBy: {
            field: "tracking_id", 
            ascOrder: true
        },
    }

    componentDidMount() {
        this.props.onFetchTrackingIds(this.props.compId, this.props.token);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        return (this.props.trackingIdsList !== nextProps.trackingIdsList) || 
                (this.state.addNewDialogOpen !== nextState.addNewDialogOpen) || 
                (this.state.tableSortBy !== nextState.tableSortBy) || 
                (this.state.linkCopySuccess !== nextState.linkCopySuccess);
    }

    snackbarClosedHandler = () => {
        this.setState({
            SnackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    addTrackindIdHandler = () => {
        this.setState({addNewDialogOpen: true});
    };

    
    

    tableSortHandler = (sortField, sortDir = false) => {

        let updatedState = {...this.state};
        
        const updatedSortBy = updateObject( updatedState.tableSortBy, {field: sortField, ascOrder: !sortDir});

        updatedState = updateObject(updatedState, {tableSortBy: updatedSortBy});
        this.setState(updatedState);
    } 
    
    updateTrackingIdHandler = (event, trackingId) => {
        this.setState({
            editTrackingId: trackingId,
            isEditDialog: true,
            addNewDialogOpen: true
        });
    }

    dialogClosedHandler = (updateList = false) => {
        this.setState({
            addNewDialogOpen: false,
            editTrackingId: null,
            isEditDialog: false,
        });
        // if list update required after closing new/update trackingid dialog
        if(updateList)
            this.props.onFetchTrackingIds(this.props.compId, this.props.token);

    }

    copyTrackindIdHandler = (event, trackingIdLink) => {
        const el = document.createElement('textarea');
        el.value = "https://" + window.location.host + "/tracking?trackid=" + trackingIdLink;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        
        this.setState({linkCopySuccess: true});
    }

    linkCopyClosedHandler = () => {
        this.setState({
            linkCopySuccess:false
        });
    }

    onDeleteTrackingId = (trackingToken) => {

        // serverApi.delete('/trackingid/delete?authToken=' + this.props.token, { data: {trackingToken: trackingToken, compId: this.props.compId} })
        serverApi.delete('/trackingid/delete?authToken=' + this.props.token, {
            headers: {
              Authorization: this.props.token
            },
            data: {trackingToken: trackingToken, compId: this.props.compId}
          })
            .then( response => {
                // success
                if(response.data.success) {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Successfully deleted!"
                        }
                    });
                    setTimeout(() => {
                        this.props.onFetchTrackingIds(this.props.compId, this.props.token);
                    }, 500);
                } else {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    SnackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: error.message
                    }
                });
            } );
    }

    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.SnackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.SnackbarAlert.alertType}
                handleClose={this.snackbarClosedHandler}>
                {this.state.SnackbarAlert.message}
            </Snackbar>
        ): null;

        const dialogContent = this.state.addNewDialogOpen? (
            <Dialog
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={this.state.addNewDialogOpen}
                onClose={this.dialogClosedHandler}>
                    {/* if passed updateContent with tracking id, form will be opened in edit mode */}
                    <TrackingIdsForm 
                        handleClose={this.dialogClosedHandler}
                        authToken = {this.props.token}
                        compId = {this.props.compId}
                        updateContent={this.state.isEditDialog}
                        contentData={this.state.editTrackingId} />
            </Dialog>
        ) : null;

        
        return(


            <React.Fragment>
                <CssBaseline />
                <Container maxWidth={false}>
                    <TableContainer component={Paper}>
                        <Toolbar>
                            <Typography edge="start" variant="h5">
                                Verwalten von Sendungsnummern
                            </Typography>
                            {/* <Button color="inherit">Login</Button> */}
                            <div className={classes.grow} />

                                            
                            {/* <IconButton
                                edge="end"
                                aria-label="add new"
                                aria-haspopup="true"
                                size="medium"
                                onClick={this.addTrackindIdHandler}
                                color="primary" >
                                <AddCircleIcon />
                            </IconButton> */}

                            <Button 
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={this.addTrackindIdHandler} >
                                    Neu
                            </Button>
                        </Toolbar>
                        
                        {dialogContent}

                        <TrackingIdsTable
                                trackingIdsList = {this.props.trackingIdsList}
                                hanleCopy = {this.copyTrackindIdHandler}
                                sortHandle = {this.tableSortHandler}
                                sortBy = {this.state.tableSortBy}
                                linkCopySuccess = {this.state.linkCopySuccess}
                                linkCopyClosed={this.linkCopyClosedHandler}
                                hanleDelete = {this.onDeleteTrackingId}
                                authToken = {this.props.token}
                                columnConfig = {this.state.columnConfig}
                                handleEdit = {this.updateTrackingIdHandler} />
                    </TableContainer>
                </Container>
                {alertSnackBar}
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        trackingIdsList: state.trackingId.trackingIds,
        isAuthenticated: state.auth.token !== null,
        compId: state.auth.compId,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchTrackingIds: (compId, authToken) => dispatch( actions.fetchTrackingIds(compId, authToken) )
        
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(TrackingID) );