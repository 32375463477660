import React, { Component } from 'react';
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
    OverlayView,
    MarkerClusterer
} from "@react-google-maps/api";

import markerVanIcon from 'assets/images/van.svg';
import markerDestIcon from 'assets/images/home.svg';
import Spinner from '../UI/Spinner/Spinner';
import {formateOrderStatus} from 'shared/utility';

class MapWrapped extends Component {

    state = {
        scriptLoaded: false,
        selectedOrder: null,
        markerLabels:[],
        hoveredOrder: null,
        mapOptions: {
            center: { lat: 50.110924, lng: 8.682127 },
            zoom: 8,
        },
        mapRef: null,
    }

    componentDidMount() {
        if(this.props.dialogOrder){
            const order = this.props.selectedOrder;
            let zoom = 9;
            if(order.estimated_distance < 2)
                zoom = 16;
            else if(order.estimated_distance < 3)
                zoom = 15;
            else if(order.estimated_distance < 5)
                zoom = 14;
            else if(order.estimated_distance < 8)
                zoom = 13;
            else if(order.estimated_distance < 11)
                zoom = 12;
            else if(order.estimated_distance < 20)
                zoom = 11;
            else if(order.estimated_distance < 35)
                zoom = 10;
            const mapOptions = {
                center: { lat: +order.vehicle_lat, lng: +order.vehicle_lon },
                zoom: zoom,
            };

            this.setState({mapOptions: {...mapOptions}});
        }
    } 

    scriptLoadedHandler = () => {
        this.setState({scriptLoaded: true});
    }

    itBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        this.props.orders.map(order => bounds.extend({ lat: +order.vehicle_lat, lng: +order.vehicle_lon }));
        map.fitBounds(bounds);
    }

    mapLoadedHandler = map => {
        this.setState({mapRef: map});
    }

    orderSelectedHandler = (order) => {
        this.setState({
            selectedOrder: order
        });
    };

    infoWindowClosedHandler = () => this.setState({selectedOrder:null});

    getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
        return {
            x: offsetWidth + labelAnchor.x,
            y: offsetHeight + labelAnchor.y,
        };
    };

    setMarkerLabels = (markerLables) => {
        this.setState({
            markerLabels:markerLables
        });
    }

    markerLabelsHandler = (clusterer) => {
        const markerLabelsList = [];

        const labelAnchor = { x: -30, y: 0 }

        let allClusters = clusterer.clusters, allMarkers;
        allClusters.forEach((cluster, clusterIndex) => {
            allMarkers = cluster.getMarkers();
            allMarkers.forEach((marker, MarkerIndex) => {
                if (allMarkers.length < 2) {
                    markerLabelsList.push(
                            <OverlayView
                                key={'m_' + clusterIndex + '_' + MarkerIndex}
                                position={marker.position}
                                mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(x, y) => this.getPixelPositionOffset(x, y, labelAnchor)}>
                                    <div
                                    style={{
                                    background: `#203254`,
                                    padding: `7px 12px`,
                                    fontSize: '11px',
                                    color: `white`,
                                    borderRadius: '4px',
                                    }}>
                                    {marker.title}
                                    </div>
                            </OverlayView>)
                }
            });
        });
        this.setMarkerLabels(markerLabelsList)
    }

    markerHover = (order) => this.setState({
        hoveredOrder: order
    });

    render() {
        
        const mapContainerStyle = {
          height: "100%",
          width: "100%",
        };

        const options = {
            // styles: mapStyles,
            disableDefaultUI: true,
            zoomControl: true,
        };

        const infoWindow =  this.state.selectedOrder ? (
            <InfoWindow 
                zIndex= {0}
                position={{ 
                    lat: +this.state.selectedOrder.vehicle_lat, 
                    lng: +this.state.selectedOrder.vehicle_lon }}
                onCloseClick={() => this.infoWindowClosedHandler()} >
                <div>
                    <h2 style={{textAlign:'center'}}>Auftrag: {this.state.selectedOrder.order_id}</h2>
                    <p>{formateOrderStatus(this.state.selectedOrder).title}</p>
                    
                </div>
            </InfoWindow>
        ): null;

        const mapOptions = {
            mapCenter: { lat: 50.110924, lng: 8.682127 },
            zoom: 8,
        };
        let mapContents = <Spinner />;
        if(this.props.orders && this.state.scriptLoaded) {
            let bounds = new window.google.maps.LatLngBounds();
            mapContents = (
                <React.Fragment>
                    <MarkerClusterer
                            averageCenter
                            enableRetinaIcons
                            onClusteringEnd={this.markerLabelsHandler}
                            options={{imagePath:"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"}}>
    
                            {
                                (clusterer) => this.props.orders.map((order) => {
                                    bounds.extend({ lat: +order.vehicle_lat, lng: +order.vehicle_lon });
                                    return <Marker
                                        key={order.order_id}
                                        position={{ lat: +order.vehicle_lat, lng: +order.vehicle_lon }}
                                        onClick={() => this.orderSelectedHandler(order)}
                                        title={order.vehicle_id}
                                        clusterer={clusterer} 
                                        onMouseOver={() => this.markerHover(order)}
                                        onMouseOut={() => this.markerHover(null)}
                                        icon = {{
                                            url: `${markerVanIcon}`,
                                            anchor: new window.google.maps.Point(15, 10),
                                            origin: new window.google.maps.Point(0, 0),
                                            scaledSize: (this.state.hoveredOrder && this.state.hoveredOrder.order_id === order.order_id)? new window.google.maps.Size(40,40):new window.google.maps.Size(32,32)
                                        }}
                                        />;
                                    })
                            }
                        </MarkerClusterer>
                        {this.state.markerLabels}
                        {infoWindow}
                </React.Fragment>
            );
        } else if(this.props.dialogOrder && this.state.scriptLoaded) {
            const order = this.props.selectedOrder;

            mapOptions.mapCenter = { lat: +order.vehicle_lat, lng: +order.vehicle_lon };
            mapOptions.zoom = 18;
            
            mapContents = (
                <React.Fragment>
                    <Marker
                        position={{ lat: +order.vehicle_lat, lng: +order.vehicle_lon }}
                        icon = {{
                            url: `${markerVanIcon}`,
                            scaledSize: new window.google.maps.Size(40,40)
                        }}
                    />  
                    <Marker
                        position={{ lat: +order.order_lat, lng: +order.order_lon }}
                        icon = {{
                            url: `${markerDestIcon}`,
                            scaledSize: new window.google.maps.Size(32,32)
                        }}
                    />      
                </React.Fragment>
            );
        }


        return (
            <LoadScript
                loadingElement= {<Spinner />}
                onLoad = {this.scriptLoadedHandler}
                googleMapsClientId = {process.env.REACT_APP_GOOGLE_CLIENT_ID} >
                <GoogleMap
                    id="map"
                    ref="googleMap"
                    zoom={this.state.mapOptions.zoom}
                    mapContainerStyle={mapContainerStyle}
                    defaultCenter ={this.state.mapOptions.center}
                    center ={this.state.mapOptions.center}
                    options={options}>
                        
                        {/* <MarkerClusterer
                            averageCenter
                            enableRetinaIcons
                            onClusteringEnd={this.markerLabelsHandler}
                            options={{imagePath:"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"}}>

                            {
                                (clusterer) => this.props.orders.map((order) => (
                                    <Marker
                                        key={order.order_id}
                                        position={{ lat: +order.vehicle_lat, lng: +order.vehicle_lon }}
                                        onClick={() => this.orderSelectedHandler(order)}
                                        title={order.vehicle_id}
                                        clusterer={clusterer} 
                                        onMouseOver={() => this.markerHover(order)}
                                        onMouseOut={() => this.markerHover(null)}
                                        icon = {{
                                            url: `${markerVanIcon}`,
                                            scaledSize: (this.state.hoveredOrder && this.state.hoveredOrder.order_id === order.order_id)? new window.google.maps.Size(40,40):new window.google.maps.Size(32,32)
                                        }}
                                        />
                                ))
                            }
                        </MarkerClusterer>
                        {this.state.markerLabels}
                        {infoWindow} */}

                        {mapContents}


                </GoogleMap>
            </LoadScript>
        );
    }
}


export default MapWrapped;