import React, { Component } from 'react';
import {serverApi} from 'axiosHelper';
// @material-ui/core components
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Snackbar from 'components/UI/Snackbar/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
// @material-ui/icons
// core components
import styles from "assets/jss/components/trackingIdsFormStyle";
import ChipTextInput from 'components/UI/ChipTextInput/ChipTextInput';
import ChipsContainer from 'components/UI/ChipsContainer/ChipsContainer';
import { updateObject, checkWhiteSpaces, insertItem, removeItem } from 'shared/utility';

class TrackingIdsForm extends Component {

    state = {
        trackingIdForm: {
            reference: {
                value: '',
                valid: false,
                touched: false
            },
            keywordInput: {
                value: '',
                valid: false,
                touched: false
            },
            keywords: {
                list: [],
                valid: false,
                touched: false
            },
            columnFilters: ["order_status", "vehicle_id", "client", "order_id", "planned_arrival", "estimated_arrival", "estimated_distance"]
        },
        filterContentCheck: false,
        SnackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }

    componentDidMount() {
        if(this.props.updateContent) {

            const updateRefernce = updateObject(this.state.trackingIdForm.reference, {
                value: this.props.contentData.reference,
                valid: true,
                touched: true
            });

            const updateKeywords = updateObject(this.state.trackingIdForm.keywords, {
                list: this.props.contentData.keywords,
                valid: true,
                touched: true
            });

            const updatedForm = updateObject(this.state.trackingIdForm, {
                reference: updateRefernce,
                keywords: updateKeywords,
                keywordInput: {
                    value: '',
                    valid: false,
                    touched: false
                },
                columnFilters: this.props.contentData.columnFilters
            });
            
            this.setState({
                trackingIdForm: updatedForm});
        }
    }

    inputChangedHandler = (event) => {

        if(!checkWhiteSpaces(event.target.value)) {
            const updatedFormElement = updateObject(this.state.trackingIdForm.keywordInput, {
                value: event.target.value,
                valid: true,
                touched: true
            });
            const updatedForm = updateObject(this.state.trackingIdForm, {
                keywordInput: updatedFormElement
            });
            
            this.setState({trackingIdForm: updatedForm});
        } else {
            this.setState({
                SnackbarAlert: {
                    status: true,
                    alertType: 'warning',
                    message: "Whitespaces not allowed!"
                }
            });
        }
    }

    referenceChangedHandler = (event) => {

        const updatedFormElement = updateObject(this.state.trackingIdForm.reference, {
            value: event.target.value,
            valid: true,
            touched: true
        });
        const updatedForm = updateObject(this.state.trackingIdForm, {
            reference: updatedFormElement
        });
        
        this.setState({trackingIdForm: updatedForm});
    }

    addKeywordHandler = (event) => {

        const keywordTrimmed = this.state.trackingIdForm.keywordInput.value.replace(/\s/g,'');

        if(this.state.trackingIdForm.keywords.list.indexOf(keywordTrimmed) !== -1) {
            this.setState({
                SnackbarAlert: {
                    status: true,
                    alertType: 'warning',
                    message: "Keyword already exists!"
                }
            });
        } else {
            const updatedKeywords = insertItem(this.state.trackingIdForm.keywords.list, {item: keywordTrimmed});


            const updatedFormElement = updateObject(this.state.trackingIdForm.keywords, {
                list: updatedKeywords,
                valid: true,
                touched: true
            });
            const updatedForm = updateObject(this.state.trackingIdForm, {
                keywords: updatedFormElement,
                keywordInput: {
                    value: '',
                    valid: false,
                    touched: false
                }
            });
            
            this.setState({trackingIdForm: updatedForm});
        }

        
    }

    removeKeywordHandler = (event, keyword, index) => {
        

        const updatedKeywords = removeItem(this.state.trackingIdForm.keywords.list, {item: keyword, index: index});

        const updatedFormElement = updateObject(this.state.trackingIdForm.keywords, {
            list: updatedKeywords,
            valid: (updatedKeywords.length > 0),
            touched: true
        });
        const updatedForm = updateObject(this.state.trackingIdForm, {
            keywords: updatedFormElement
        });
        
        this.setState({trackingIdForm: updatedForm});
    }

    snackbarClosedHandler = () => {
        this.setState({
            SnackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    formSubmitHandler = () => {
        if(this.state.trackingIdForm.keywords.valid && this.state.trackingIdForm.reference.valid) {
            let payload = {
                compId: this.props.compId,
                filters: {
                    keywords: this.state.trackingIdForm.keywords.list,
                    reference: this.state.trackingIdForm.reference.value,
                    columnFilters: this.state.trackingIdForm.columnFilters
                }
            }

            let apiEndpoint = "generate";
    
            if(this.props.updateContent) {
                payload = {...payload,  trackingToken: this.props.contentData.tracking_id};
                apiEndpoint = "update";
            }
    
            serverApi.post( `/trackingid/${apiEndpoint}?authToken=${this.props.authToken}`, payload)
                .then( response => {
                    if(response.data.success) {
                        this.setState({
                            SnackbarAlert: {
                                status: true,
                                alertType: 'success',
                                message: (apiEndpoint === "generate")? "TrackingID erfolgreich generiert!" : "Erfolgreich geupdated!"
                            }
                        });
                        setTimeout(() => {
                            this.props.handleClose(true);
                        }, 1000);
                    } else {
                        this.setState({
                            SnackbarAlert: {
                                status: true,
                                alertType: 'error',
                                message: response.data.error
                            }
                        });
                    }
                } )
                .catch( error => {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: "Aufgabe konnte nicht abgeschlossen werden. Bitte versuch es später!"
                        }
                    });
                } );
        } else {
            console.warn("[TrackingIdsForm.js]", "TODO: form validation repsonse");
        }
    }

    

    filterSwitchHandler = () => {
        this.setState(prevState => {
            const updatedCheck = !prevState.filterContentCheck;
            return {
                filterContentCheck: updatedCheck
            }
        });
    }
    
    filterCheckChanged = event => {

        let updatedKeywords = null;

        const columnFilters = this.state.trackingIdForm.columnFilters;

        if(columnFilters.includes(event.target.name)) {
            updatedKeywords = removeItem(columnFilters, {item: event.target.name, index: columnFilters.indexOf(event.target.name)});
        } else {
            updatedKeywords = insertItem(columnFilters, {item: event.target.name});
        }

        const updatedForm = updateObject(this.state.trackingIdForm, {
            columnFilters: updatedKeywords
        });
        
        this.setState({trackingIdForm: updatedForm});
    }
  
    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.SnackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.SnackbarAlert.alertType}
                handleClose={this.snackbarClosedHandler}>
                {this.state.SnackbarAlert.message}
            </Snackbar>
        ): null;
        const columnConfig = [
            {
                field: 'client',
                title: 'Kunde'
            },
            {
                field: 'order_id',
                title: 'Auftrag'
            },
            {
                field: 'planned_arrival',
                title: 'geplante Ankunft'
            },
            {
                field: 'estimated_arrival',
                title: 'Ankunftszeit ca.'
            },
            {
                field: 'estimated_distance',
                title: 'KM'
            }
        ];

        const filterCheckboxes = this.state.filterContentCheck ? (
            
            <Paper className={classes.filterCheckBoxes} elevation={3}>
                {
                    columnConfig.map(column => {
                        return <FormControlLabel
                            style={{width:"45%"}}
                            key = {column.field}
                            control={<Checkbox checked={this.state.trackingIdForm.columnFilters.includes(column.field)} onChange={this.filterCheckChanged} name={column.field} />}
                            label={column.title}
                        />
                    })
                }
            </Paper>
        ) : null ;

        return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {this.props.updateContent? "Tracking-ID aktualisieren": "Tracking-ID generieren"}
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="reference"
                            label="Referenzbeschreibung"
                            value={this.state.trackingIdForm.reference.value}
                            onChange={this.referenceChangedHandler}
                            name="reference"
                            autoComplete="reference"
                            autoFocus />
                            
                        <Divider className={classes.dividerHorizontal} orientation="horizontal" />
                        
                        <Paper className={classes.chipsBox} elevation={3}>

                            <ChipTextInput
                                variant="outlined"
                                placeholder = "Geben Sie hier das Schlüsselwort ein"
                                inputProps = {{ 'aria-label': 'Geben Sie hier das Schlüsselwort ein' }}
                                handleInsert= {this.addKeywordHandler}
                                inputChanged= {this.inputChangedHandler}
                                keyword={this.state.trackingIdForm.keywordInput.value}
                                isValid={this.state.trackingIdForm.keywordInput.valid} />
            
                            <ChipsContainer
                                keywords={this.state.trackingIdForm.keywords.list}
                                handleDelete={this.removeKeywordHandler} />

                        </Paper>
                            
                        <Divider className={classes.dividerHorizontal} orientation="horizontal" />

                        
                        <Grid 
                            component="label" 
                            container  
                            direction="row"  
                            justify="space-between"  
                            alignItems="center">
                            <Grid item>
                            <Typography variant="button" gutterBottom>
                                Sichtbare Datenspalten
                            </Typography>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    label={this.state.filterContentCheck? "nur ausgewählt":"zeige alles"}
                                    labelPlacement = "start"
                                    control={<Switch checked={this.state.filterContentCheck} onChange={this.filterSwitchHandler} name="filterContent" />}
                                />
                            </Grid>
                        </Grid>

                        {filterCheckboxes}
                        
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.formSubmitHandler} >
                            Speichern
                        </Button>
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this.props.handleClose} >
                            schließen
                        </Button>
                        {alertSnackBar}
                    </div>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles, {withTheme: true})(TrackingIdsForm);
