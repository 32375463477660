import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {serverApi} from 'axiosHelper';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import {updateObject} from 'shared/utility';
import styles from 'assets/jss/containers/authStyle';
import * as actions from 'store/actions/index';
import Snackbar from 'components/UI/Snackbar/Snackbar';


class PasswordGenerate extends Component {

    state = {
        passwordField: {
            error: false,
            helperText: null,
            value:""
        },
        rePasswordField: {
            error: false,
            helperText: null,
            value:""
        },
        responseHandled: false,
        snackbarAlert: {
            status: false,
            alertType: null,
            message: null
        },
        token: null,
        tokenVerified: false,
        tokenResponse: null,
        compId: null,
        reqType: null
    }

    shouldComponentUpdate ( nextProps, nextState ) {
        // if(this.props !== nextProps ||
        //     this.state.rePasswordField !== nextState.rePasswordField ||
        //     this.state.passwordField !== nextState.passwordField ||
        //     this.state.snackbarAlert !== nextState.snackbarAlert)
            // return true;
        // else 
        //     return false;
        return true;
    }
    
    componentDidMount() {

        const urlParam = queryString.parse(this.props.location.search);
        if(urlParam.token && urlParam.token.trim() !== '' && urlParam.type && urlParam.type.trim() !== '') {  
            const reqType =  urlParam.type.trim();
            const queryParams = '?authToken=' + urlParam.token.trim();
            serverApi.get( '/company/verifytoken' + queryParams)
                .then( res => {
                    if(res.data.success === true) {
                        this.setState(updateObject(this.state, {
                            token: urlParam.token.trim(), 
                            tokenVerified : true, 
                            reqType: reqType,
                            compId: res.data.token.data.compid
                        }));
                    } else {
                        if(res.data.error === "Expired token") {
                            this.setState(updateObject(this.state, {
                                tokenVerified : false, 
                                tokenResponse: reqType === 1? "Token abgelaufen. Bitte wenden Sie sich an das Support-Team.":"Token abgelaufen. Bitte fordern Sie erneut an, das Passwort zurückzusetzen." 
                            }));
                        } else {
                            this.setState(updateObject(this.state, {
                                tokenVerified : false, 
                                tokenResponse: "Unterbrochene oder manipulierte Verbindung. Bitte wenden Sie sich an das Support-Team. Vielen Dank." 
                            }));
                            
                        }
                    }
                } )
                .catch( error => {
                    if (error.response) {
                        this.setState(updateObject(this.state, {tokenVerified : false, tokenResponse: error.response.data }));
                    } else if (error.request) {
                        this.setState(updateObject(this.state, {tokenVerified : false, tokenResponse: error.message }));
                    } else {
                        this.setState(updateObject(this.state, {tokenVerified : false, tokenResponse: error.message }));
                    }
                } 
            ); 
        } else {
            this.setState(updateObject(this.state, {tokenVerified : false, tokenResponse: "Ungültiger oder falscher Link" }));
        }
        
        
    }

    onPasswordChanged = (event) => {
        const updatedValue = event.target.value;
        let updatedField = null;
        if(updatedValue.length < 1) {
            updatedField = updateObject(this.state.passwordField, {
                value : event.target.value,
                error: true,
                helperText: "Kann nicht leer sein!"
            });
        } else {
            updatedField = updateObject(this.state.passwordField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {passwordField : updatedField}));
    };

    onRePasswordChanged = (event) => {
        const updatedValue = event.target.value;
        let updatedField = null;
        if(updatedValue !== this.state.passwordField.value) {
            updatedField = updateObject(this.state.rePasswordField, {
                value : event.target.value,
                error: true,
                helperText: "Passwort stimmt nicht überein!"
            });
        } else {
            updatedField = updateObject(this.state.rePasswordField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {rePasswordField : updatedField}));
    };

    validateForm = () => {
        
        let isValid = true;
        const {passwordField, rePasswordField} = this.state;
        let updatedpasswordField = null;
        let updatedRepasswordField = null;

        if(passwordField.value.length < 1) {
            updatedpasswordField = updateObject(this.state.passwordField, {
                error: true,
                helperText: "Kann nicht leer sein!"
            });
            isValid = false;
        } if(passwordField.length < 6 || passwordField.length > 12) {
            updatedpasswordField = updateObject(this.state.passwordField, {
                error: true,
                helperText: "Das Passwort muss zwischen 6 und 12 Zeichen lang sein"
            });
        } else {
            updatedpasswordField = updateObject(this.state.passwordField, {
                error: false,
                helperText: null
            });
        }
        
        if(rePasswordField.value !==  passwordField.value) {
            updatedRepasswordField = updateObject(this.state.rePasswordField, {
                error: true,
                helperText: "Passwort stimmt nicht überein!"
            });
            isValid = false;
        } else {
            updatedRepasswordField = updateObject(this.state.rePasswordField, {
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {passwordField: updatedpasswordField, rePasswordField : updatedRepasswordField}));
        return isValid;
        
    }
    
    onSubmitHandler = (event) => {
        event.preventDefault();
        if(this.validateForm()) {
            const authData = {
                userId: this.state.compId,
                password: this.state.passwordField.value
            };
            const queryParam = "?authToken=" + this.state.token;
            serverApi.put( `/company/changepassword${queryParam}`, authData)
            .then( response => {
                if(response.data.success) {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Passwort gespeichert"
                        }
                    });
                    setTimeout(()=> {
                        this.props.history.push("/admin");
                    }, 1000);
                    
                } else {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    snackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: "Aufgabe konnte nicht abgeschlossen werden. Bitte versuch es später!"
                    }
                });
            } );
        }
        
    }

    SnackbarClosedHandler = () => {
        this.setState({
            snackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    redirectHandler = (path) => {
        this.props.history.push(path);
    }


    render() {

        const { classes } = this.props;
        
        const alertSnackBar = this.state.snackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.snackbarAlert.alertType}
                handleClose={this.SnackbarClosedHandler}>
                {this.state.snackbarAlert.message}
            </Snackbar>
        ): null;

        const responseBox = this.state.tokenResponse? (
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ErrorOutlineIcon />
                </Avatar>
                <Typography component="h1" variant="h5">{this.state.tokenResponse}</Typography>  
            </div>
        ) : (<div className={classes.paper}>
                <CircularProgress /> 
            </div>);

        const passwordForm = (
            <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">{+this.state.reqType === 1 ? "Neues Passwort generieren": "Passwort zurücksetzen"}</Typography>
                    <form className={classes.form} noValidate onSubmit={this.onSubmitHandler}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Passwort"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={this.state.passwordField.helperText}
                            error={this.state.passwordField.error}
                            value={this.state.passwordField.value}
                            onChange= {this.onPasswordChanged}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="repassword"
                            label="Wiederhole das Passwort"
                            type="password"
                            id="repassword"
                            autoComplete="re-password"
                            helperText={this.state.rePasswordField.helperText}
                            error={this.state.rePasswordField.error}
                            value={this.state.rePasswordField.value}
                            onChange= {this.onRePasswordChanged}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled = {this.state.rePasswordField.error || this.state.passwordField.error}
                            className={classes.submit}
                        >
                            Passwort speichern
                        </Button>
                    </form>
                </div>
        );

        return (
            <Container component="main" maxWidth="xs">
                {alertSnackBar}
                <CssBaseline />
                {this.state.tokenVerified? passwordForm: responseBox}
                
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        isAdmin: state.auth.isAdmin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( compId, password, isAdmin ) => dispatch( actions.auth( compId, password, isAdmin ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(PasswordGenerate) );
