
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import MapIcon from '@material-ui/icons/Map';
import LinkIcon from '@material-ui/icons/Link';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import PageviewIcon from '@material-ui/icons/Pageview';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

export const navigationRoutes = {
    tracking : {
        primary: [
            {
                path: "/orders/ankunft",
                name: "Ankunft",
                icon: WatchLaterIcon
            },
            {
                path: "/tracking",
                name: "Neu suchen",
                icon: PageviewIcon
            }
        ],
        secondary: []
    },
    admin : {
        primary: [
            {
                path: "/admin/orders/ankunft",
                name: "Ankunft",
                icon: WatchLaterIcon
            },
            {
                path: "/admin/orders/map",
                name: "Map Karte",
                icon: MapIcon
            },
            {
                path: "/admin/trackingid",
                name: "Tracking IDs",
                icon: LinkIcon
            },
        ],
        secondary: [
            {
                path: "/admin/settings",
                name: "Einstellungen",
                icon: SettingsIcon
            },
            {
                path: "/admin/logout",
                name: "Ausloggen",
                icon: ExitToAppIcon
            },
        ],
    },
    support : {
        primary: [
            {
                path: "/support/customers",
                name: "Kunde",
                icon: PeopleAltIcon
            },
        ],
        secondary: [
            {
                path: "/support/settings",
                name: "Einstellungen",
                icon: SettingsIcon
            },
            {
                path: "/support/logout",
                name: "Ausloggen",
                icon: ExitToAppIcon
            },
        ],
    }
};
