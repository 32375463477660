import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    customers: [],
    companiesList: [],
    loading: false,
    requestResponse: null
};

const fetchCustomersStart = ( state, action ) => {
    return updateObject( state, { 
        loading: true
    } );
};

const fetchCustomersSuccess = ( state, action ) => {
    return updateObject( state, {
        customers: action.customers,
        loading: false
    } );
};

const fetchCustomersFailed = ( state, action ) => {
    return updateObject( state, { 
        loading: false,
        requestResponse: {
            action:  "FETCH_ALL",
            reqStatus: false,
            error: action.error
        }
    } );
};

const fetchCompaniesListSuccess = ( state, action ) => {
    return updateObject( state, {
        companiesList: action.companiesList
    } );
};

const fetchCompaniesListFailed = ( state, action ) => {
    return updateObject( state, { 
        requestResponse: {
            action:  action.type,
            reqStatus: false,
            error: action.error
        }
    } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_CUSTOMERS_START: return fetchCustomersStart( state, action );
        case actionTypes.FETCH_CUSTOMERS_SUCCESS: return fetchCustomersSuccess( state, action );
        case actionTypes.FETCH_CUSTOMERS_FAIL: return fetchCustomersFailed( state, action );
        
        case actionTypes.FETCH_COMPANIES_SUCCESS: return fetchCompaniesListSuccess( state, action );
        case actionTypes.FETCH_COMPANIES_FAIL: return fetchCompaniesListFailed( state, action );
        default: return state;
    }
};

export default reducer;