import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as actions from 'store/actions/index';
import Orders from 'containers/Orders/Orders';
import TrackingNumberInput from 'containers/TrackingNumberInput/TrackingNumberInput';

class TrackingApp extends Component {

    constructor(props) {
        super(props);
        this.props.onSetAdmin(false, "tracking");
    }

    componentDidMount() {
        if(localStorage.getItem('msm_trackingNumber')) {
            this.props.onGetTrackingInfo(localStorage.getItem('msm_trackingNumber'));
        }
    }

    render() {

        // if(this.props.trackingInfo.trackingId) {
            
            let redirectPath = "/tracking";
            const storedTrackingNumber = localStorage.getItem('msm_trackingNumber');
            if(storedTrackingNumber) {
                redirectPath = "/orders";
            }

            const routes = (
                <Switch>
                    <Route path="/tracking" component={TrackingNumberInput} />
                    <Route path="/orders" component={Orders} />
                    <Redirect to={redirectPath} />
                </Switch>
            );


            return (
                <React.Fragment>
                    {routes}
                </React.Fragment>
            );
        // } else {
        //    return <Spinner style={{width:"100vw", heigt: "100vh"}} />;
        // } 
        
    }
}

const mapStateToProps = state => {
    return {
        trackingInfo: state.auth.trackingInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAdmin: (isAdmin, appType) => dispatch( actions.setAdmin(isAdmin, appType) ),
        onGetTrackingInfo: ( trackingNumber ) => dispatch( actions.getTrackingInfo( trackingNumber ) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( TrackingApp );