import { fade } from '@material-ui/core/styles';
import {successColor, warningColor, dangerColor, infoColor} from '../appStyle';

const ordersStyle = theme => ({
    
    grow: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    textSuccess: {
        color: successColor[0]
    },
    textWarning: {
        color: warningColor[0]
    },
    textError: {
        color: dangerColor[0]
    },
    customBadge: {
        right: 8,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
    badgeSuccess: {
        backgroundColor: successColor[0],
        color: "white"
    },
    badgeDanger: {
        backgroundColor: dangerColor[0],
        color: "white"
    },
    badgeInfo: {
        backgroundColor: infoColor[0],
        color: "white"
    },
    badgeWarn: {
        backgroundColor: warningColor[0],
        color: "white"
    }
});

export default ordersStyle;