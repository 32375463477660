import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {updateObject} from 'shared/utility';
import styles from 'assets/jss/containers/authStyle';
import * as actions from 'store/actions/index';
import Snackbar from 'components/UI/Snackbar/Snackbar';

class Auth extends Component {
    
    state = {
        // userId: for admin's it will be compId and for support team, it will be email
        userIdField: {
            error: false,
            helperText: null,
            value:"",
        },
        passwordField: {
            error: false,
            helperText: null,
            value:""
        },
        rememberMe: false,
        responseHandled: false,
        snackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }

    shouldComponentUpdate ( nextProps, nextState ) {
        if(this.props !== nextProps ||
            this.state.userIdField !== nextState.userIdField ||
            this.state.passwordField !== nextState.passwordField ||
            this.state.snackbarAlert !== nextState.snackbarAlert ||
            this.state.rememberMe !== nextState.rememberMe)
            return true;
        else 
            return false;
    }

    static getDerivedStateFromProps(props, state) {
        if(state.responseHandled) {
            return {};
        // }
        // else if ( props.isAuthenticated  ) {
            // return {
            //     responseHandled: true,
            //     snackbarAlert: {
            //         status: true,
            //         alertType: 'success',
            //         message: 'Success!'
            //     }
            // };
        } else if(props.error) {
            return {
                responseHandled: true,
                snackbarAlert: {
                    status: true,
                    alertType: 'error',
                    message: JSON.stringify(props.error)
                }
            };
        } else {
            return {};
        } 
    }

    onCompIDChanged = event => {
        const updatedValue = event.target.value;
        let updatedField = null;
        if(updatedValue.length < 1) {
            updatedField = updateObject(this.state.userIdField, {
                value : event.target.value,
                error: true,
                helperText: "Kann nicht leer sein!"
            });
        } else {
            updatedField = updateObject(this.state.userIdField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {userIdField : updatedField}));
    }
    
    onEmailChanged = event => {
        const updatedValue = event.target.value;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let updatedField = null;
        
        if ( re.test(updatedValue) ) {
            updatedField = updateObject(this.state.userIdField, {
                value : updatedValue,
                error: false,
                helperText: null
            });
        }
        else {
            updatedField = updateObject(this.state.userIdField, {
                value : updatedValue,
                error: true,
                helperText: "Ungültiger Benutzername!"
            });
        }
        this.setState(updateObject(this.state, {userIdField : updatedField}));
    
    }

    onPasswordChanged = event => {
        const updatedValue = event.target.value;
        let updatedField = null;
        if(updatedValue.length < 1) {
            updatedField = updateObject(this.state.passwordField, {
                value : event.target.value,
                error: true,
                helperText: "Kann nicht leer sein!"
            });
        } else {
            updatedField = updateObject(this.state.passwordField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {passwordField : updatedField}));
    };

    onRememberMeChanged = event => {
        this.setState(prevState => {
            return {
                rememberMe : !prevState.rememberMe
            }
        });
    }

    validateLoginForm = () => {
        
        let isValid = true;
        const {userIdField, passwordField} = this.state;
        let updatedUserIdField = null;
        let updatedpasswordField = null;

        if(this.props.appType === "admin") {
            if(userIdField.value.length < 1) {
                updatedUserIdField = updateObject(this.state.userIdField, {
                    error: true,
                    helperText: "Kann nicht leer sein!"
                });
                isValid = false;
            } else {
                updatedUserIdField = updateObject(this.state.userIdField, {
                    error: false,
                    helperText: null
                });
            }
        } else {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( re.test(userIdField.value) ) {
                updatedUserIdField = updateObject(this.state.userIdField, {
                    error: false,
                    helperText: null
                });
            }
            else {
                updatedUserIdField = updateObject(this.state.userIdField, {
                    error: true,
                    helperText: "Ungültiger Benutzername!"
                });
                isValid = false;
            }
        }

        if(passwordField.value.length < 1) {
            updatedpasswordField = updateObject(this.state.passwordField, {
                error: true,
                helperText: "Kann nicht leer sein!"
            });
            isValid = false;
        } else {
            updatedpasswordField = updateObject(this.state.passwordField, {
                error: false,
                helperText: null
            });
        }

        this.setState(updateObject(this.state, {userIdField : updatedUserIdField, passwordField: updatedpasswordField}));
        return isValid;
        
    }
    
    onLoginHandler = (event) => {
        event.preventDefault();
        if(this.validateLoginForm()) {
            this.setState({
                responseHandled: false
            });
            this.props.onAuth(this.state.userIdField.value, this.state.passwordField.value, this.state.rememberMe, this.props.isAdmin);
        }
        
    }

    SnackbarClosedHandler = () => {
        this.setState({
            snackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    redirectHandler = (path) => {
        this.props.history.push(path);
    }


    render() {

        const { classes } = this.props;

        let authRedirect = null;
        if ( this.props.isAuthenticated ) {
            authRedirect = <Redirect to={this.props.authRedirectPath} />
        }
        
        const alertSnackBar = this.state.snackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.snackbarAlert.alertType}
                handleClose={this.SnackbarClosedHandler}>
                {this.state.snackbarAlert.message}
            </Snackbar>
        ): null;

        return (
            <Container component="main" maxWidth="xs">
                {authRedirect}
                {alertSnackBar}
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {this.props.appType === "admin"? "Arrival Monitor Admin Login" : "Arrival Monitor Support Login"}
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.onLoginHandler}>
                        
                        {
                            this.props.appType === "support"? (
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Benutzername"
                                    name="email"
                                    autoFocus
                                    helperText={this.state.userIdField.helperText}
                                    error={this.state.userIdField.error}
                                    value={this.state.userIdField.value}
                                    autoComplete="Benutzername"
                                    onChange= {this.onEmailChanged}
                                />
                            ):(
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="accessCode"
                                    label="Mandantennummer"
                                    name="accessCode"
                                    autoFocus
                                    helperText={this.state.userIdField.helperText}
                                    error={this.state.userIdField.error}
                                    value={this.state.userIdField.value}
                                    autoComplete="Mandantennummer"
                                    onChange= {this.onCompIDChanged}
                                />
                            )
                        }
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Kennwort"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={this.state.passwordField.helperText}
                            error={this.state.passwordField.error}
                            value={this.state.passwordField.value}
                            onChange= {this.onPasswordChanged}
                        />
                        <FormControlLabel
                            control={<Checkbox 
                                        checked={this.state.rememberMe}
                                        onChange= {this.onRememberMeChanged}
                                        name="rememberme"
                                        color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled = {this.state.userIdField.error || this.state.passwordField.error}
                            className={classes.submit}
                        >
                            Einloggen
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                            <Link href="#" variant="body2">
                                Passwort vergessen?
                            </Link>
                            </Grid>
                        </Grid> */}
                    </form>
                </div>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        isAdmin: state.auth.isAdmin,
        appType: state.auth.appType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( userId, password, rememberMe, isAdmin ) => dispatch( actions.auth( userId, password, rememberMe, isAdmin ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(Auth) );
