import React, { Component } from 'react';
import { connect } from 'react-redux';
import {serverApi} from 'axiosHelper';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import AddCircleIcon from '@material-ui/icons/AddCircle';

// core components
import CustomersTable from 'components/CustomersTable/CustomersTable';
import Dialog from 'components/UI/DialogContainer/DialogContainer';
import CustomersForm from './CustomersForm/CustomersForm';
import styles from 'assets/jss/containers/trackingIdManagerStyle';
import Snackbar from 'components/UI/Snackbar/Snackbar';
import * as actions from 'store/actions/index';
import { updateObject } from 'shared/utility';

class Customers extends Component {

    state = {
        addNewDialogOpen : false,
        editCustomer: null,
        isEditDialog: false,
        snackbarAlert: {
            status: false,
            alertType: null,
            message: null
        },
        columnConfig: [
            {
                field: 'COMP_ID',
                title: 'Comp ID'
            },
            {
                field: 'NAME1',
                title: 'Name'
            },
            {
                field: 'COMP_EMAIL',
                title: 'Email'
            },
            {
                field: 'SUB_DOMAIN',
                title: 'Domänenname'
            },
        ],
        tableSortBy: {
            field: "COMP_ID", 
            ascOrder: true
        },
    }

    componentDidMount() {
        this.props.onFetchCustomers(this.props.token);
        this.props.onFetchCompanies(this.props.token);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        return (this.props.customers !== nextProps.customers) || 
        (this.state.addNewDialogOpen !== nextState.addNewDialogOpen) || 
        (this.state.tableSortBy !== nextState.tableSortBy) || 
        (this.state.snackbarAlert !== nextState.snackbarAlert);
    }

    snackbarClosedHandler = () => {
        this.setState({
            snackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    addTrackindIdHandler = () => {
        this.setState({addNewDialogOpen: true});
    };
    
    updateCustomerHandler = (event, customer) => {
        this.setState({
            editCustomer: customer,
            isEditDialog: true,
            addNewDialogOpen: true
        });
    }

    dialogClosedHandler = (updateList = false) => {
        this.setState({
            addNewDialogOpen: false,
            editCustomer: null,
            isEditDialog: false,
        });
        // if list update required after closing new/update trackingid dialog
        if(updateList)
            this.props.onFetchCustomers(this.props.token);
    }

    onDeleteCustomer = (compId) => {

        serverApi.delete('/customers/delete?authToken=' + this.props.token, {
            headers: {
              Authorization: this.props.token
            },
            data: {compId: compId}
          })
            .then( response => {
                // success
                if(response.data.success) {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Erfolgreich gelöscht!"
                        }
                    });
                    setTimeout(() => {
                        this.props.onFetchCustomers(this.props.token);
                    }, 500);
                } else {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    snackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: error.message
                    }
                });
            } );
    }

    resetPasswordHandler = (compId) => {

        const payload = {
            compId: compId,
            domain: window.location.host
        };
        serverApi.post('/forgotpassword?authToken=' + this.props.token, payload)
            .then( response => {
                // success
                if(response.data.success) {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'success',
                            message: "Eine an den Kunden gesendete E-Mail mit einem Link zum Zurücksetzen des Passworts."
                        }
                    });
                    setTimeout(() => {
                        this.props.onFetchCustomers(this.props.token);
                    }, 500);
                } else {
                    this.setState({
                        snackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: response.data.error
                        }
                    });
                }
            } )
            .catch( error => {
                this.setState({
                    snackbarAlert: {
                        status: true,
                        alertType: 'error',
                        message: error.message
                    }
                });
            } );
    }

    

    tableSortHandler = (sortField, sortDir = false) => {

        let updatedState = {...this.state};
        
        const updatedSortBy = updateObject( updatedState.tableSortBy, {field: sortField, ascOrder: !sortDir});

        updatedState = updateObject(updatedState, {tableSortBy: updatedSortBy});
        this.setState(updatedState);
    } 

    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.snackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.snackbarAlert.alertType}
                handleClose={this.snackbarClosedHandler}>
                {this.state.snackbarAlert.message}
            </Snackbar>
        ): null;

        const dialogContent = this.state.addNewDialogOpen? (
            <Dialog
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={this.state.addNewDialogOpen}
                onClose={this.dialogClosedHandler}>
                    {/* if passed updateContent with tracking id, form will be opened in edit mode */}
                    <CustomersForm 
                        handleClose={this.dialogClosedHandler}
                        authToken = {this.props.token}
                        compId = {this.props.compId}
                        companiesList = {this.props.companiesList}
                        updateContent={this.state.isEditDialog}
                        contentData={this.state.editCustomer} />
            </Dialog>
        ) : null;

        
        return(


            <React.Fragment>
                <CssBaseline />
                <Container maxWidth={false}>
                    <TableContainer component={Paper}>
                        <Toolbar>
                            <Typography edge="start" variant="h5">Kunden verwalten</Typography>
                            {/* <Button color="inherit">Login</Button> */}
                            <div className={classes.grow} />

                                            
                            {/* <IconButton
                                edge="end"
                                aria-label="add new"
                                aria-haspopup="true"
                                size="medium"
                                onClick={this.addTrackindIdHandler}
                                color="primary" >
                                <AddCircleIcon />
                            </IconButton> */}

                            <Button 
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={this.addTrackindIdHandler} >
                                    Neu
                            </Button>
                        </Toolbar>
                        
                        {dialogContent}

                        <CustomersTable
                                customers = {this.props.customers}
                                sortHandle = {this.tableSortHandler}
                                sortBy = {this.state.tableSortBy}
                                columnConfig = {this.state.columnConfig}
                                hanleDelete = {this.onDeleteCustomer}
                                authToken = {this.props.token}
                                handleResetPassword = {this.resetPasswordHandler}
                                handleEdit = {this.updateCustomerHandler} />
                    </TableContainer>
                </Container>
                {alertSnackBar}
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        customers: state.customer.customers,
        companiesList: state.customer.companiesList,
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomers: (authToken) => dispatch( actions.fetchCustomers(authToken) ),
        onFetchCompanies: (authToken) => dispatch( actions.fetchCompaniesList(authToken) ),
        
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(Customers) );