import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginTop: 5
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  placeHolderText: {
      fontSize: 12,
      color: '#999'
  }
}));

const ChipsContainer = (props) => {
    const classes = useStyles();
    // const [chipData, setChipData] = React.useState([
    //     { key: 0, label: 'Angular' },
    //     { key: 1, label: 'jQuery' },
    //     { key: 2, label: 'Polymer' },
    //     { key: 3, label: 'React' },
    //     { key: 4, label: 'Vue.js' },
    // ]);

    const keywordChips = props.keywords.map((keyword, index) => {

        const chipElem = props.disabled? (
            <Chip 
                    label={keyword}
                    size="small" />
            ):(
                <Chip
                        label={keyword}
                        onDelete={(event) => props.handleDelete(event, keyword, index)}
                        className={classes.chip}
                    />
            );

        return (
            <li key={index}>
                {chipElem}
            </li>
        );
    });

    const placeHolderText = (
        <Typography className={classes.placeHolderText} color="textSecondary" gutterBottom>
            Please add keywords
        </Typography>
    );

    return (
        <Paper component="ul" className={classes.root} elevation={0}>
            {props.keywords.length > 0 ? keywordChips :  placeHolderText}
        </Paper>
    );
}

export default ChipsContainer;
