
import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from 'assets/jss/components/appBarStyle';
import RefreshIcon from '@material-ui/icons/Refresh';
import {format} from 'date-fns';

const useStyles = makeStyles(styles);

const NavAppBar = ( props ) => {
    const classes = useStyles();

    let loadingBar = null;
    if(props.isDataLoading)
        loadingBar = <LinearProgress />;

    const lastUpdate = props.lastUpdate ? 
                            (<React.Fragment>
                                <IconButton onClick={props.refreshButtonClicked} color="inherit" aria-label="refresh" component="span">
                                    <RefreshIcon />
                                </IconButton>
                                <Typography color="inherit">
                                    letzte Aktualisierung: {format(props.lastUpdate, 'dd.MM.yyyy HH:mm:ss' )}
                                </Typography>
                                </React.Fragment>): null;

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, props.drawerOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.drawerOpenClicked}
                    className={clsx(classes.menuButton, props.drawerOpen && classes.menuButtonHidden)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {props.title}
                </Typography>
                {lastUpdate}
            </Toolbar>
            {loadingBar}
        </AppBar>
    );
};


export default NavAppBar;