    import {
    defaultFont,
    primaryColor,
    dangerColor,
    grayColor
    } from "../appStyle";
    import tooltipStyle from "../userInterface/tooltipStyle";
    import checkboxAdnRadioStyle from "../userInterface/checkboxAdnRadioStyle";

    const trackingIdsTableStyle = theme => ({
    ...tooltipStyle,
    ...checkboxAdnRadioStyle,
    table: {
        marginBottom: "0",
        overflow: "visible"
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5]
    },
    tableActions: {
        display: "flex",
        border: "none",
        padding: "12px 8px !important",
        verticalAlign: "middle"
    },
    tableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "16px"
    },
    tableCellHeader:{
        background: "#ffffff",
        cursor: "pointer", 
        "&:hover": {
            background: "#efefef"
        }
    },
    tableCellHeaderSorter:{

        display: "flex", 
        flexFlow: "row", 
        justifyContent: "flex-start",
    },
    tableCellRTL: {
        textAlign: "right"
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0"
    },
    tableActionButtonIcon: {
        width: "17px",
        height: "17px"
    },
    reset: {
        backgroundColor: "transparent",
        color: grayColor[0],
        boxShadow: "none"
    },
    edit: {
        backgroundColor: "transparent",
        color: primaryColor[0],
        boxShadow: "none"
    },
    close: {
        backgroundColor: "transparent",
        color: dangerColor[0],
        boxShadow: "none"
    }
    });
    export default trackingIdsTableStyle;
