import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    compId: null,
    userName: null,
    compName: "Arrival Monitor",
    error: null,
    loading: false,
    authRedirectPath: '/',
    isAdmin: false,
    appType: "tracking",
    themeColorsLoaded: false,
    themeColors: {
        primary: "#3f50b5",
        secondary: "#f44336"
    },
    trackingInfo: {
        trackingId: null,
        compId: null,
        compName: null,
        error: null
    }
};

const setAdmin = ( state, action ) => {
    return updateObject( state, { isAdmin: action.isAdmin, appType: action.appType } );
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    if(state.appType === "admin") {
        return updateObject( state, { 
            token: action.idToken,
            compId: action.userId,
            compName: action.compName,
            error: null,
            loading: false
         } );
    } else {
        return updateObject( state, { 
            token: action.idToken,
            userName: action.userId,
            error: null,
            loading: false
         } );
    }
    
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, compId: null, compName: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const setTrackingInfo = (state, action) => {
    const updatedTrackingInfo = updateObject(state.trackingInfo, {
        trackingId: action.trackingId,
        compId: action.compId,
        compName: action.compName,
        error:null
    });

    return updateObject( state, {
        trackingInfo: updatedTrackingInfo
    } );
};

const trackingInfoFail = (state, action) => {
    const updatedTrackingInfo = updateObject(state.trackingInfo, {
        trackingId: action.trackingId,
        compId: null,
        compName: null,
        error:action.error
    });

    return updateObject( state, {
        trackingInfo: updatedTrackingInfo
    } );
};

const startThemeColors = ( state, action ) => {
    return updateObject( state, { themeColorsLoaded: false } );
};
const setThemeColors = ( state, action ) => {
    return updateObject( state, { themeColors: action.themeColors, themeColorsLoaded: true } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_ADMIN: return setAdmin(state, action);
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.SET_TRACKING_INFO: return setTrackingInfo(state, action);
        case actionTypes.SET_TRACKING_INFO_FAIL: return trackingInfoFail(state, action);
        case actionTypes.START_THEME_COLORS: return startThemeColors(state, action);
        case actionTypes.SET_THEME_COLORS: return setThemeColors(state, action);
        default:
            return state;
    }
};


export default reducer;