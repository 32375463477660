import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import {updateObject} from 'shared/utility';
import styles from 'assets/jss/containers/trackingNumberInputStyle';
import * as actions from 'store/actions/index';
import Snackbar from 'components/UI/Snackbar/Snackbar';
import queryString from 'query-string';

class TrackingNumberInput extends Component {

    state = {
        trackingNumberVal: "",
        snackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }

    shouldComponentUpdate ( nextProps, nextState ) {
        if(this.props !== nextProps ||
            this.state.trackingNumberVal !== nextState.trackingNumberVal ||
            this.state.snackbarAlert !== nextState.snackbarAlert)
            return true;
        else 
            return false;
    }
    
    componentDidMount() {
        let trackId = null;
        const urlParam = queryString.parse(this.props.location.search);
        if(urlParam.trackid && urlParam.trackid.trim() !== '') {
            trackId = urlParam.trackid.trim(); 
            this.setState(updateObject(this.state, {trackingNumberVal : trackId}));      
        }
        
        localStorage.removeItem('msm_trackingNumber');
    }

    componentDidUpdate() {
        if(this.props.trackingInfo.trackingId && localStorage.getItem('msm_trackingNumber') === this.props.trackingInfo.trackingId) {
            if(this.props.trackingInfo.error) {
                this.setState(updateObject(this.state, {snackbarAlert : {
                    status: true,
                    alertType: "warning",
                    message: this.props.trackingInfo.error
                }}));
            } else {
                this.props.history.push('/orders');
            }
        }
    }

    onTrackingNumberChanged = event => {
        this.setState(updateObject(this.state, {trackingNumberVal : event.target.value}));
    }
    
    onTrackingHandler = (event) => {
        event.preventDefault();
        const trackingNumber = this.state.trackingNumberVal;
        if(trackingNumber.length === 32 ) {
            this.props.onGetTrackingInfo(trackingNumber);
        } else {
            this.setState(updateObject(this.state, {snackbarAlert : {
                status: true,
                alertType: "warning",
                message: "Ungültige TrackingID"
            }}));
        }
        
    }

    SnackbarClosedHandler = () => {
        this.setState({
            snackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    redirectHandler = (path) => {
        this.props.history.push(path);
    }


    render() {

        const { classes } = this.props;

        // let authRedirect = null;
        // if ( this.props.trackingNumber ) {
        //     authRedirect = <Redirect to={"/orders"} />
        // }
        
        const alertSnackBar = this.state.snackbarAlert.status? (
            <Snackbar 
                opened={this.state.snackbarAlert.status} 
                alertType= {this.state.snackbarAlert.alertType}
                handleClose={this.SnackbarClosedHandler}>
                {this.state.snackbarAlert.message}
            </Snackbar>
        ): null;

        return (

            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    
                    <div className={classes.paper}>
                        <Typography variant="h3" gutterBottom style={{marginBottom:"50px"}}>
                            MSM Arrival Monitor
                        </Typography>          
                        <Avatar className={classes.avatar}>
                            <LocalShippingIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sendung verfolgen
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={this.onTrackingHandler}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="trackingid"
                                label="TrackingID"
                                name="trackingid"
                                autoFocus
                                value={this.state.trackingNumberVal}
                                autoComplete="companyid"
                                onChange= {this.onTrackingNumberChanged}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Suchen
                            </Button>
                        </form>
                    </div>
                </Grid>
                {alertSnackBar}
            </Grid>
        );
    }
}


const mapStateToProps = state => {
    return {
        trackingInfo: state.auth.trackingInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetTrackingInfo: ( trackingNumber ) => dispatch( actions.getTrackingInfo( trackingNumber ) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withStyles(styles, {withTheme: true})(TrackingNumberInput) );
