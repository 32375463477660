import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    trackingIds: [],
    loading: false,
    requestResponse: null
};

const fetchTrackingIdsStart = ( state, action ) => {
    return updateObject( state, { 
        loading: true
    } );
};

const fetchTrackingIdsSuccess = ( state, action ) => {
    return updateObject( state, {
        trackingIds: action.trackingIds,
        loading: false
    } );
};

const fetchTrackingIdsFailed = ( state, action ) => {
    return updateObject( state, { 
        loading: false,
        requestResponse: {
            action:  "FETCH_ALL",
            reqStatus: false,
            error: action.error
        }
    } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_TRACKING_IDS_START: return fetchTrackingIdsStart( state, action );
        case actionTypes.FETCH_TRACKING_IDS_SUCCESS: return fetchTrackingIdsSuccess( state, action );
        case actionTypes.FETCH_TRACKING_IDS_FAIL: return fetchTrackingIdsFailed( state, action );
        default: return state;
    }
};

export default reducer;