import React, { Component } from 'react';
import {serverApi} from 'axiosHelper';
// @material-ui/core components
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from 'components/UI/Snackbar/Snackbar';
// @material-ui/icons
// core components
import styles from "assets/jss/components/trackingIdsFormStyle";
import { updateObject } from 'shared/utility';

class CustomersForm extends Component {

    state = {
        compIdField: {
            error: false,
            helperText: null,
            value:"",
        },
        subDomainField: {
            error: false,
            helperText: null,
            value:"",
        },
        emailField: {
            error: false,
            helperText: null,
            value:"",
        },
        SnackbarAlert: {
            status: false,
            alertType: null,
            message: null
        }
    }

    componentDidMount() {
        if(this.props.updateContent) {
            const updatedCompId = updateObject(this.state.compIdField, {
                value : this.props.contentData.COMP_ID,
                error: false,
                helperText: null
            });
            const updatedSubDomain = updateObject(this.state.subDomainField, {
                value : this.props.contentData.SUB_DOMAIN,
                error: false,
                helperText: null
            });
            const updatedEmailId = updateObject(this.state.emailField, {
                value : this.props.contentData.COMP_EMAIL,
                error: false,
                helperText: null
            });
            
            
            this.setState(updateObject(this.state, {
                compIdField : updatedCompId,
                emailField : updatedEmailId,
                subDomainField: updatedSubDomain
            }));
        }
    }

    onEmailChanged = event => {
        
        const email = event.target.value;
        let updatedField = null;

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if ( re.test(email) ) {
            updatedField = updateObject(this.state.emailField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        else {
            updatedField = updateObject(this.state.emailField, {
                value : event.target.value,
                error: true,
                helperText: "Ungültige E-Mail-Adresse!"
            });
        }
        this.setState(updateObject(this.state, {emailField : updatedField}));
    
    }
    
    formValidation = () => {       

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const regex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?/;

        if(this.state.compIdField.value.length < 1) {
            const updatedField = updateObject(this.state.compIdField, {
                error: true,
                helperText: "Kann nicht leer sein!"
            });
            this.setState(updateObject(this.state, {compIdField : updatedField}));
            return false;
        }else if(this.state.subDomainField.value.length < 1) {
            const updatedField = updateObject(this.state.subDomainField, {
                error: true,
                helperText: "Kann nicht leer sein!"
            });
            this.setState(updateObject(this.state, {subDomainField : updatedField}));
            return false;
        }else if(!regex.test(this.state.subDomainField.value)) {
            const updatedField = updateObject(this.state.subDomainField, {
                error: true,
                helperText: "Ungültiger Subdomänenname"
            });
            this.setState(updateObject(this.state, {subDomainField : updatedField}));
            return false;
        }else if(!re.test(this.state.emailField.value)) {
            const updatedField = updateObject(this.state.emailField, {
                error: true,
                helperText: "Ungültige E-Mail-Adresse!"
            });
            this.setState(updateObject(this.state, {emailField : updatedField}));
            return false;
        } else {
            return true;
        }
    }

    onCompIDChanged = event => {
        const updatedValue = event.target.value;
        let updatedField = null;
        if(updatedValue.length < 1) {
            updatedField = updateObject(this.state.compIdField, {
                value : event.target.value,
                error: true,
                helperText: "Kann nicht leer sein!"
            });
        } else {
            updatedField = updateObject(this.state.compIdField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {compIdField : updatedField}));
    }

    onSubDomainChanged = event => {
        const updatedValue = event.target.value;
        let updatedField = null;
        const regex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?/;
        if(updatedValue.length < 1) {
            updatedField = updateObject(this.state.subDomainField, {
                value : event.target.value,
                error: true,
                helperText: "Kann nicht leer sein!"
            });
        } else if(!regex.test(updatedValue)) {
            updatedField = updateObject(this.state.subDomainField, {
                value : event.target.value,
                error: true,
                helperText: "Ungültiger Subdomänenname"
            });
        } else {
            updatedField = updateObject(this.state.subDomainField, {
                value : event.target.value,
                error: false,
                helperText: null
            });
        }
        this.setState(updateObject(this.state, {subDomainField : updatedField}));
    }

    snackbarClosedHandler = () => {
        this.setState({
            SnackbarAlert: {
                status: false,
                alertType: null,
                message: null
            }
        });
    }

    formSubmitHandler = () => {
        const {compIdField, subDomainField, emailField} = this.state;
        if(compIdField.error || subDomainField.error || emailField.error || !this.formValidation()) {
            console.warn("[CustomersForm.js]", "TODO: form validation repsonse");
            return false;
        } else {
            let payload = {
                compId: compIdField.value,
                email: emailField.value,
                subdomain: subDomainField.value
            };

            let apiEndpoint = this.props.updateContent? "update" : "add";
    
            serverApi.post( `/customers/${apiEndpoint}?authToken=${this.props.authToken}`, payload)
                .then( response => {
                    if(response.data.success) {
                        this.setState({
                            SnackbarAlert: {
                                status: true,
                                alertType: 'success',
                                message: (apiEndpoint === "add")? "Neukunde registriert!" : "Erfolgreich geupdated!"
                            }
                        });
                        setTimeout(() => {
                            this.props.handleClose(true);
                        }, 1000);
                    } else {
                        this.setState({
                            SnackbarAlert: {
                                status: true,
                                alertType: 'error',
                                message: response.data.error
                            }
                        });
                    }
                } )
                .catch( error => {
                    this.setState({
                        SnackbarAlert: {
                            status: true,
                            alertType: 'error',
                            message: "Aufgabe konnte nicht abgeschlossen werden. Bitte versuch es später!"
                        }
                    });
                } );
        }
    }
  
    render() {
        const { classes } = this.props;

        const alertSnackBar = this.state.SnackbarAlert.status? (
            <Snackbar 
                opened={true} 
                alertType= {this.state.SnackbarAlert.alertType}
                handleClose={this.snackbarClosedHandler}>
                {this.state.SnackbarAlert.message}
            </Snackbar>
        ): null;

        return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {this.props.updateContent? "Kunden bearbeiten": "Kunden registrieren"}
                    </Typography>
                    <div className={classes.form}>
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="accessCode"
                            label="Kundennummer"
                            name="accessCode"
                            disabled={this.props.updateContent}
                            autoFocus
                            helperText={this.state.compIdField.helperText}
                            error={this.state.compIdField.error}
                            value={this.state.compIdField.value}
                            autoComplete="Kundennummer"
                            onChange= {this.onCompIDChanged}
                        />
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="subdomain"
                            label="Name der Unterdomäne"
                            name="subdomain"
                            disabled={this.props.updateContent}
                            autoFocus
                            helperText={this.state.subDomainField.helperText}
                            error={this.state.subDomainField.error}
                            value={this.state.subDomainField.value}
                            autoComplete="subdomain"
                            onChange= {this.onSubDomainChanged}
                        />
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoFocus
                            helperText={this.state.emailField.helperText}
                            error={this.state.emailField.error}
                            value={this.state.emailField.value}
                            autoComplete="Email"
                            onChange= {this.onEmailChanged}
                        />
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={this.state.compIdField.error || this.state.emailField.error}
                            className={classes.submit}
                            onClick={this.formSubmitHandler} >
                            Speichern
                        </Button>
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this.props.handleClose} >
                            schließen
                        </Button>
                        {alertSnackBar}
                    </div>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles, {withTheme: true})(CustomersForm);
