import * as actionTypes from './actionTypes';
import {serverApi} from '../../axiosHelper';

// Actions for fetching tracking ids list
export const fetchCustomersStart = () => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_START
    };
};

export const fetchCustomersSuccess = ( customersList ) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        customers: customersList
    };
};

export const fetchCustomersFailed = (errorMsg) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_FAIL,
        error: errorMsg
    };
};

export const fetchCustomers = authToken => {
    return dispatch => {
        dispatch( fetchCustomersStart() );
        const queryParams = 'authToken=' + authToken;
        serverApi.get( '/customers?' + queryParams )
            .then( response => {
                if(response.data.success) {
                    dispatch(fetchCustomersSuccess(response.data.rows));
                }
                else {
                    dispatch(fetchCustomersFailed(response.data.error));
                }
            } )
            .catch( error => {
                dispatch(fetchCustomersFailed(error.message));
            } );
    };
};

export const fetchCompaniesListSuccess = ( companiesList ) => {
    return {
        type: actionTypes.FETCH_COMPANIES_SUCCESS,
        companiesList: companiesList
    };
};

export const fetchCompaniesListFailed = (errorMsg) => {
    return {
        type: actionTypes.FETCH_COMPANIES_FAIL,
        error: errorMsg
    };
};

export const fetchCompaniesList = authToken => {
    return dispatch => {
        const queryParams = 'authToken=' + authToken;
        serverApi.get( '/customers/companies?' + queryParams )
            .then( response => {
                if(response.data.success) {
                    dispatch(fetchCompaniesListSuccess(response.data.rows));
                }
                else {
                    dispatch(fetchCompaniesListFailed(response.data.error));
                }
            } )
            .catch( error => {
                dispatch(fetchCompaniesListFailed(error.message));
            } );
    };
};