import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomSnackbar = (props) => {
    return (
        <Snackbar open={props.opened} autoHideDuration={3000} onClose={props.handleClose}>
            <Alert onClose={props.handleClose} severity={props.alertType}>
                {props.children}
            </Alert>
        </Snackbar>
    );
}

CustomSnackbar.propTypes = {
    opened: PropTypes.bool,
    alertType: PropTypes.oneOf(['warning', 'error', 'info', 'success']),
};

export default CustomSnackbar;
