import {serverApi} from '../../axiosHelper';

import * as actionTypes from './actionTypes';

export const setTrackingInfo = (compId, compName, keywords, trackingId) => {
    return {
        type: actionTypes.SET_TRACKING_INFO,
        trackingId: trackingId,
        compId: compId,
        compName: compName,
        keywords: keywords
    };
};

export const trackingInfoFail = (trackingId, error) => {
    return {
        type: actionTypes.SET_TRACKING_INFO_FAIL,
        trackingId: trackingId,
        error: error
    };
};

export const getTrackingInfo = (trackingId) => {
    return dispatch => {
        const queryParams = '?trackingId=' + trackingId;
        serverApi.get('/trackinginfo' + queryParams)
            .then(response => {
                if(response.data.success) {
                    // compId, compName, filter Keywords, tracking id, error if any
                    localStorage.setItem('msm_trackingNumber', trackingId);
                    dispatch(setTrackingInfo(response.data.compId, response.data.compName, response.data.keywords, trackingId));
                }
                else {
                    dispatch(trackingInfoFail(trackingId, response.data.error));
                }
            })
            .catch(err => {
                dispatch(trackingInfoFail(trackingId, err.message));
            });
    };
};

export const setThemeColors = (themeColors, isDefault) => {
    return {
        type: actionTypes.SET_THEME_COLORS,
        themeColors: themeColors,
        isDefault: isDefault
    };
};

export const startThemeColors = () => {
    return {
        type: actionTypes.START_THEME_COLORS
    };
};

export const getThemeColors = (domainName) => {
    return dispatch => {
        dispatch(startThemeColors());
        const queryParams = '?subd=' + domainName;
        serverApi.get('/company/themecolor' + queryParams)
            .then(response => {
                if(response.data.success) {
                    // compId, compName, filter Keywords, tracking id, error if any
                    // localStorage.setItem('msm_trackingNumber', trackingId);
                    dispatch(setThemeColors({
                        primary: response.data.themeColors[0],
                        secondary: response.data.themeColors[1]
                    }, false));
                }
                else {
                    dispatch(setThemeColors({
                        primary: "#3f50b5",
                        secondary: "#f44336"
                    }, true));
                }
            })
            .catch(err => {
                dispatch(setThemeColors({
                    primary: "#3f50b5",
                    secondary: "#f44336"
                }, true));
            });
    };
};

export const setAdmin = (isAdmin, appType) => {
    return {
        type: actionTypes.SET_ADMIN,
        isAdmin: isAdmin,
        appType: appType,
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, compName) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        compName: compName
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = (isAdmin) => {
    const userType = isAdmin ? "admin": "support";
    localStorage.removeItem('msm_token_' + userType);
    localStorage.removeItem('msm_expirationDate_' + userType);
    localStorage.removeItem('msm_userId_' + userType);
    localStorage.removeItem('msm_compName_' + userType);
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime, isAdmin) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout(isAdmin));
        }, expirationTime * 1000);
    };
};

export const auth = (userId, password, rememberMe, isAdmin) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            userId: userId,
            password: password,
            isAdmin: isAdmin
        };
        serverApi.post('/authenticate', authData)
            .then(response => {
                if(response.data.success) {
                    const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                    if(rememberMe) {
                        const userType = isAdmin ? "admin": "support";
                        localStorage.setItem('msm_token_' + userType, response.data.token);
                        localStorage.setItem('msm_expirationDate_' + userType, expirationDate);
                        localStorage.setItem('msm_userId_' + userType, userId);
                        localStorage.setItem('msm_compName_' + userType, response.data.name);
                    }
                    dispatch(authSuccess(response.data.token, userId, response.data.name));
                    dispatch(checkAuthTimeout(response.data.expiresIn));
                }
                else {
                    dispatch(authFail(response.data.error));
                }
            })
            .catch(err => {
                dispatch(authFail(err.message));
            });
    };
};



export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = (isAdmin) => {
    return dispatch => {
        const userType = isAdmin ? "admin": "support";
        const token = localStorage.getItem('msm_token_' + userType);
        if (!token) {
            dispatch(logout(isAdmin));
        } else {
            const expirationDate = new Date(localStorage.getItem('msm_expirationDate_' + userType));
            if (expirationDate <= new Date()) {
                dispatch(logout(isAdmin));
            } else {
                const userId = localStorage.getItem('msm_userId_' + userType);
                const compName = localStorage.getItem('msm_compName_' + userType);
                dispatch(authSuccess(token, userId, compName));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000, isAdmin ));
            }   
        }
    };
};